"use client";

import {
  Label,
  Slider as AriaSlider,
  SliderThumb,
  SliderTrack,
  type SliderProps,
} from "react-aria-components";

interface ISliderProps<T> extends SliderProps<T> {
  label?: string;
  thumbLabels?: string[];
}

export function Slider<T extends number | number[]>({
  label,
  ...props
}: ISliderProps<T>): JSX.Element {
  return (
    <AriaSlider {...props} className="w-96">
      <div className="flex">
        <Label className="flex-1">{label}</Label>
        {/* <SliderOutput /> */}
      </div>
      <SliderTrack className="relative w-full h-7">
        {({ state }) => (
          <>
            <div className="absolute h-2 top-[50%] translate-y-[-50%] w-full rounded-full bg-brand-primary-light" />
            <div
              className="absolute h-2 top-[50%] translate-y-[-50%] rounded-full bg-primary"
              style={{ width: `${state.getThumbPercent(0) * 100}%` }}
            />
            <SliderThumb className="h-7 w-7 top-[50%] rounded-full border border-solid border-brand-primary bg-brand-primary transition dragging:bg-brand-secondary " />
          </>
        )}
      </SliderTrack>
    </AriaSlider>
  );
}
