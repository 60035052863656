import * as React from "react";
import { SVGProps } from "react";
export function MailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.077 1H2.923A1.923 1.923 0 0 0 1 2.923V19.27c0 1.062.861 1.923 1.923 1.923h21.154A1.923 1.923 0 0 0 26 19.27V2.923A1.923 1.923 0 0 0 24.077 1Z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 3.404 11.27 9.615a1.923 1.923 0 0 0 2.46 0L26 3.404"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
