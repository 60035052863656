"use client";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AddCircleIcon, FileIcon } from "icons";
import { useState, useRef, useCallback, ReactNode, useEffect } from "react";
import { Button as AriaButton } from "react-aria-components";
import Cropper, { ReactCropperElement } from "react-cropper";

import { Button } from "../Button/Button";
import { Heading } from "../Heading/Heading";
import { Modal } from "../Modal/Modal";
import { Text } from "../Text/Text";

import { AspectRatios } from "./aspect-ration.enum";
import "cropperjs/dist/cropper.css";

interface IImagePickerProps {
  id?: string;
  title?: string;
  imageSrc?: string;
  placeholder?: string;
  aspectRatio: keyof typeof AspectRatios;
  isLoading?: boolean;
  pickerChild?: ReactNode;
  children?: ReactNode;
  onSelectImage: (image: File) => void;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

// this type will use in crop image function that is
// converting canvas into image.
const defaultCroppedImageType = "image/png";

export function ImagePicker({
  id,
  title,
  imageSrc,
  placeholder = "Drag and drop a image or click here to select",
  aspectRatio = "1:1",
  isLoading,
  isOpen,
  onOpenChange,
  pickerChild,
  children,
  onSelectImage,
}: IImagePickerProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [selectedImage, setSelectedImage] = useState<File | undefined | string>(
    imageSrc
  );
  const [isOpenPicker, setIsOpenPicker] = useState<boolean | undefined>(isOpen);

  useEffect(() => {
    setSelectedImage(imageSrc);
  }, [imageSrc]);

  useEffect(() => {
    if (isOpenPicker) {
      onOpenChange?.(isOpenPicker);
    }
  }, [isOpenPicker, onOpenChange]);

  const cropImage = useCallback((): void => {
    if (!selectedImage) {
      return;
    }
    let fileName = "new-image";

    if (typeof selectedImage !== "string") {
      fileName = selectedImage.name;
    }

    if (typeof cropperRef.current?.cropper !== "undefined" && selectedImage) {
      const canvas = cropperRef.current?.cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName ?? "fileName", {
            type: defaultCroppedImageType,
          });
          setSelectedImage(file);
          onSelectImage(file);
          setIsOpenPicker(false);
        }
      }, defaultCroppedImageType);
    }
  }, [onSelectImage, selectedImage]);

  function onImageSelect(file: File): void {
    setSelectedImage(file);
  }

  const handleZoom = (zoomIncrement: number): void => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(zoomIncrement);
    }
  };

  const cropper = (): JSX.Element | null => (
    <div className="flex flex-1 w-full flex-col justify-between items-center p-6 gap-10">
      <Heading>Edit Your Profile Picture</Heading>
      <div className="flex flex-1 w-full flex-col md:flex-row gap-2">
        <div className="flex flex-col gap-4 flex-[2] items-center">
          <Cropper
            style={{ height: "200px", marginBottom: 5 }}
            ref={cropperRef}
            initialAspectRatio={AspectRatios[aspectRatio]}
            preview=".img-preview"
            src={
              selectedImage && typeof selectedImage !== "string"
                ? URL.createObjectURL(selectedImage)
                : selectedImage
            }
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={false}
            draggable={false}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={false}
            aspectRatio={AspectRatios[aspectRatio]}
          />
          <AriaButton
            isDisabled={isLoading}
            onPress={() => setSelectedImage(undefined)}
            className="underline"
          >
            <div className="flex gap-2 items-center justify-center">
              <AddCircleIcon className="w-4 h-4" />
              <Text>Upload a new photo</Text>
            </div>
          </AriaButton>
        </div>
        <div className="flex flex-1 items-center md:items-start justify-center flex-col gap-5">
          <Button size="xs" intent="secondary" onClick={() => handleZoom(0.1)}>
            + Zoom In
          </Button>
          <Button size="xs" intent="secondary" onClick={() => handleZoom(-0.1)}>
            - Zoom Out
          </Button>
        </div>
      </div>
      <Button isLoading={isLoading} isDisabled={isLoading} onClick={cropImage}>
        <Text>Update your picture</Text>
      </Button>
      {/* <div className="flex flex-col flex-wrap  items-start gap-4  w-[300px]">
          <Text className="mb-2" align="left">
            Preview
          </Text>
          <div
            className={`img-preview overflow-hidden ${
              isRoundedPreview ? "rounded-full" : "rounded"
            }`}
            style={{ width: "100%", height: "100px" }}
          />
        </div> */}
    </div>
  );

  const picker = (): JSX.Element => (
    <div
      className="bg-background-800 h-96 w-96 border-background-600 flex cursor-pointer flex-col justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6"
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.click();
        }
      }}
      onDrop={(dropEvent) => {
        dropEvent.preventDefault();
        if (dropEvent.dataTransfer.files[0]) {
          setSelectedImage(dropEvent.dataTransfer.files[0]);
        }
      }}
      onDragOver={(dragEvent) => {
        dragEvent.preventDefault();
      }}
    >
      <div className="flex flex-col items-center justify-center space-y-1 text-center ">
        {pickerChild ?? (
          <FileIcon className="mx-auto h-12 w-12 text-gray-500" />
        )}
        <div className="flex text-sm text-gray-600">
          <label
            htmlFor={id}
            className="relative cursor-pointer rounded-md  font-medium"
          >
            <span className="text-body-primary">{placeholder}</span>
            <input
              ref={inputRef}
              data-testid="file-upload-drop-zone"
              id={id}
              name={title}
              accept=".png,.jpg,.jpeg"
              type="file"
              className="sr-only"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  onImageSelect(e.target.files[0]);
                }
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      title={title}
      triggerNode={children}
      isDismissable
      isBackgroundBlur
      isOpen={isOpenPicker}
      onOpenChange={setIsOpenPicker}
      onOpenRequest={() => setIsOpenPicker(true)}
      animatedModalClassName=" md:min-w-[600px] min-h-[400px] flex flex-1 flex-col items-center justify-center"
    >
      {selectedImage ? cropper() : picker()}
    </Modal>
  );
}
