"use client";

import { CloseIcon } from "icons";
import React, { useState } from "react";
import { FileTrigger, Label } from "react-aria-components";
import { Button, Text } from "ui-components";

export interface IFileInputProps {
  label?: string;
  placeholder?: string;
  buttonTitle?: string;
  helperMessage?: string;
  fileName?: string;
  fileId?: number;
  acceptedFileTypes?: Array<string>;
  isLoading?: boolean;
  isRequired?: boolean;
  onChange?: (file: File | null) => void;
}

export function FileInput({
  label,
  placeholder,
  buttonTitle = "Select File",
  helperMessage,
  fileName,
  fileId,
  acceptedFileTypes,
  isLoading,
  isRequired = false,
  onChange,
}: IFileInputProps): JSX.Element {
  const [file, setFile] = useState<File | null>(null);

  const handleFileSelect = (files: FileList | null): void => {
    if (files && files[0]) {
      setFile(files[0]);
      if (onChange) {
        onChange(files[0]);
      }
    } else {
      setFile(null);
      if (onChange) {
        onChange(null);
      }
    }
  };

  const handleClearFile = (): void => {
    setFile(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div className="flex flex-1 flex-grow w-full flex-col">
      <Label>
        <Text variant="small">
          {label}
          {isRequired && (
            <Text
              variant="small"
              intent="secondary"
              className="ml-1"
              align="left"
            >
              (required)
            </Text>
          )}
        </Text>
        <div className="flex flex-1 items-center border border-background-dark rounded-md hover:border-brand-primary focus-within:border-brand-primary relative h-10">
          <div className="rounded-md outline-none p-2 flex flex-1 flex-row justify-between items-center">
            <Text className="line-clamp-1" variant="small">
              {fileId ? `${fileName}` : file ? file.name : placeholder}
            </Text>
            {fileId ? (
              <Button
                size="xxs"
                className="max-h-[30px]"
                type="button"
                onPress={handleClearFile}
              >
                <div>
                  <CloseIcon className="w-3 h-3 " />
                </div>
              </Button>
            ) : (
              <FileTrigger
                acceptedFileTypes={acceptedFileTypes}
                onSelect={(e) => {
                  handleFileSelect(e);
                }}
              >
                <Button
                  isLoading={isLoading}
                  type="button"
                  className="max-h-[30px] w-[120px]"
                >
                  {buttonTitle}
                </Button>
              </FileTrigger>
            )}
          </div>
        </div>
      </Label>
      <Text variant="small" align="left" className="mt-1">
        {helperMessage}
      </Text>
    </div>
  );
}
