import * as React from "react";
import { SVGProps } from "react";
export function HamburgerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#hamburger_svg__a)"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.285.635H.714M19.285 7.777H.714M19.285 14.92H.714" />
      </g>
      <defs>
        <clipPath id="hamburger_svg__a">
          <path fill="#fff" d="M0 0h20v15.556H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
