"use client";

/* eslint-disable no-nested-ternary */
import { VariantProps, cva } from "class-variance-authority";
import { EyeOpenIcon, EyeCloseIcon } from "icons";
import React, { useState } from "react";
import { TextField, Label, Input } from "react-aria-components";
import { UseFormRegisterReturn } from "react-hook-form";

import { Text } from "../Text/Text";

export interface IPasswordField
  extends VariantProps<typeof passwordFieldWrapperClass> {
  testId?: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  hasValue?: boolean;

  register?: UseFormRegisterReturn<string>;
}
export function PasswordField({
  testId = "password-input-test-id",
  label,
  placeholder,
  isDisabled = false,
  errorMessage = "",
  helperMessage = "",
  isRequired = false,
  hasValue = false,
  register,
  intent,
}: IPasswordField): JSX.Element {
  let calculatedIntent: "primary" | "success" | "warning" | "danger";
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  if (intent) {
    calculatedIntent = intent;
  } else if (errorMessage) {
    calculatedIntent = "danger";
  } else if (hasValue) {
    calculatedIntent = "success";
  } else {
    calculatedIntent = "primary";
  }

  function handleIconClick(): void {
    setIsPasswordVisible(!isPasswordVisible);
  }
  return (
    <TextField className="flex flex-col gap-1 flex-1">
      <Label>
        <Text variant="small" align="left">
          {label}
        </Text>
        {isRequired && (
          <Text
            variant="small"
            intent="secondary"
            className="ml-1"
            align="left"
          >
            (required)
          </Text>
        )}

        <div
          className={passwordFieldWrapperClass({ intent: calculatedIntent })}
        >
          <Input
            data-testid={testId}
            type={isPasswordVisible ? "text" : "password"}
            disabled={isDisabled}
            className={passwordFieldClass()}
            placeholder={placeholder}
            {...register}
          />
          {!isPasswordVisible ? (
            <EyeOpenIcon
              className={iconsClass()}
              onClick={() => handleIconClick()}
            />
          ) : (
            <EyeCloseIcon
              className={iconsClass()}
              onClick={() => handleIconClick()}
            />
          )}
        </div>
      </Label>

      {errorMessage ? (
        <Text variant="small" intent={calculatedIntent} align="left">
          {errorMessage}
        </Text>
      ) : calculatedIntent !== "success" ? (
        <Text variant="small" intent={calculatedIntent} align="left">
          {helperMessage}
        </Text>
      ) : null}
    </TextField>
  );
}

const passwordFieldWrapperClass = cva(
  "flex items-center border rounded-main hover:border-primary focus-within:border-primary relative",
  {
    variants: {
      intent: {
        primary: "border-background-dark",
        success: "border-background-dark",
        warning: "border-warning",
        danger: "border-danger",
      },
    },
    defaultVariants: {
      intent: "primary",
    },
  }
);

const passwordFieldClass = cva(
  "rounded-main outline-none px-2 py-3 text-bsm font-medium border-none flex-1 h-10"
);

const iconsClass = cva(
  "absolute right-4 flex items-center  text-gray-800 z-50"
);
