import * as React from "react";
import { SVGProps } from "react";
export function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#send_svg__a)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8.571 11.429.714 7.857 19.286.714l-7.143 18.572-3.572-7.857ZM8.571 11.429l4.286-4.286" />
      </g>
      <defs>
        <clipPath id="send_svg__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
