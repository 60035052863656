import * as React from "react";
import { SVGProps } from "react";
export function EyeCloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.23 4.246c.166.207.258.476.258.754 0 .279-.092.547-.258.754C12.18 7.025 9.79 9.5 7 9.5 4.21 9.5 1.82 7.025.77 5.754A1.208 1.208 0 0 1 .512 5c0-.278.092-.547.258-.754C1.82 2.975 4.21.5 7 .5c2.79 0 5.18 2.475 6.23 3.746Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
