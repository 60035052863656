import * as React from "react";
import { SVGProps } from "react";

export function PDFIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={19}
            height={24}
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group">
                <path
                    id="Vector"
                    d="M17.7738 6.22499L12.5988 1.12499C12.2988 0.824988 11.8863 0.674988 11.4738 0.674988H2.47383C1.19883 0.674988 0.111328 1.72499 0.111328 3.03749V11.85C0.111328 12.3 0.486328 12.7125 0.973828 12.7125C1.46133 12.7125 1.83633 12.3375 1.83633 11.85V2.99999C1.83633 2.62499 2.13633 2.32499 2.51133 2.32499H10.9863V7.12499C10.9863 7.57499 11.3613 7.98749 11.8488 7.98749H16.6113V11.625C16.6113 12.075 16.9863 12.4875 17.4738 12.4875C17.9613 12.4875 18.3363 12.1125 18.3363 11.625V7.34999C18.2238 6.93749 18.0738 6.52499 17.7738 6.22499ZM12.6363 3.52499L15.4488 6.29999H12.6363V3.52499Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_2"
                    d="M17.3992 19.425C16.9492 19.425 16.5367 19.8 16.5367 20.2875V21.0375C16.5367 21.4125 16.2367 21.7125 15.8617 21.7125H2.47422C2.09922 21.7125 1.79922 21.4125 1.79922 21.0375V20.5125C1.79922 20.0625 1.42422 19.65 0.936719 19.65C0.449219 19.65 0.0742188 20.025 0.0742188 20.5125V21C0.0742188 22.275 1.12422 23.3625 2.43672 23.3625H15.8242C17.0992 23.3625 18.1867 22.3125 18.1867 21V20.25C18.2242 19.8 17.8492 19.425 17.3992 19.425Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_3"
                    d="M14.0625 14.25H16.125C16.575 14.25 16.9875 13.875 16.9875 13.3875C16.9875 12.9 16.6125 12.525 16.125 12.525H14.0625C13.05 12.525 12.1875 13.3875 12.1875 14.4V18.9375C12.1875 19.3875 12.5625 19.8 13.05 19.8C13.5375 19.8 13.9125 19.425 13.9125 18.9375V16.575H15.3375C15.7875 16.575 16.2 16.2 16.2 15.7125C16.2 15.225 15.825 14.85 15.3375 14.85H13.875V14.3625C13.875 14.3625 13.95 14.25 14.0625 14.25Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_4"
                    d="M8.9625 19.8375C10.275 19.8375 11.3625 18.75 11.3625 17.4V14.925C11.3625 13.575 10.275 12.4875 8.9625 12.4875H7.2375C6.7875 12.4875 6.375 12.8625 6.375 13.35V19.0125C6.375 19.4625 6.75 19.875 7.2375 19.875H8.9625V19.8375ZM8.0625 14.175H8.925C9.3 14.175 9.6375 14.5125 9.6375 14.925V17.4C9.6375 17.8125 9.3 18.15 8.925 18.15H8.0625V14.175Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_5"
                    d="M2.24922 19.8375C2.69922 19.8375 3.11172 19.4625 3.11172 18.975V16.725H4.94922C5.39922 16.725 5.81172 16.35 5.81172 15.8625V13.3875C5.81172 12.9375 5.43672 12.525 4.94922 12.525H2.24922C1.79922 12.525 1.38672 12.9 1.38672 13.3875V18.975C1.42422 19.4625 1.79922 19.8375 2.24922 19.8375ZM3.11172 14.25H4.08672V15.0375H3.11172V14.25Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
