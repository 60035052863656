import * as React from "react";
import { SVGProps } from "react";
export function FacebookIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#facebook_svg__a)">
        <path
          d="M29.952 14.973c0-8.268-6.702-14.97-14.97-14.97C6.715.003.012 6.705.012 14.973c0 7.02 4.834 12.911 11.354 14.53v-9.955H8.28v-4.575h3.087v-1.971c0-5.095 2.306-7.457 7.308-7.457.949 0 2.585.186 3.255.372v4.146c-.353-.037-.967-.055-1.73-.055-2.454 0-3.402.93-3.402 3.347v1.618h4.89l-.84 4.575h-4.05v10.286c7.411-.896 13.155-7.207 13.155-14.861Z"
          fill="#0866FF"
        />
        <path
          d="m20.846 19.548.84-4.575h-4.89v-1.618c0-2.417.949-3.347 3.403-3.347.762 0 1.376.018 1.73.056V5.916c-.67-.186-2.306-.372-3.255-.372-5.002 0-7.308 2.362-7.308 7.457v1.971H8.279v4.575h3.087v9.954a14.998 14.998 0 0 0 5.43.332V19.547h4.05Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="facebook_svg__a">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
