import * as React from "react";
import { SVGProps } from "react";
export function YoutubeNoColorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={41} height={41} rx={2} fill="silver" fillOpacity={0.2} />
      <path
        d="M21 12c.855 0 1.732.022 2.582.058l1.004.048.961.057.9.061.822.064a3.802 3.802 0 0 1 3.494 3.423l.04.425.075.91c.07.943.122 1.971.122 2.954 0 .983-.052 2.011-.122 2.954l-.075.91c-.013.146-.026.287-.04.425a3.802 3.802 0 0 1-3.495 3.423l-.82.063-.9.062-.962.057-1.004.048A61.58 61.58 0 0 1 21 28a61.58 61.58 0 0 1-2.582-.058l-1.004-.048-.961-.057-.9-.062-.822-.063a3.802 3.802 0 0 1-3.494-3.423l-.04-.425-.075-.91A40.617 40.617 0 0 1 11 20c0-.983.052-2.011.122-2.954l.075-.91c.013-.146.026-.287.04-.425a3.802 3.802 0 0 1 3.493-3.423l.821-.064.9-.061.962-.057 1.004-.048c.86-.037 1.722-.057 2.583-.058Zm0 2c-.825 0-1.674.022-2.5.056l-.978.047-.939.055-.882.06-.808.063a1.801 1.801 0 0 0-1.666 1.623C13.11 17.113 13 18.618 13 20c0 1.382.11 2.887.227 4.096.085.872.777 1.55 1.666 1.623l.808.062.882.06.939.056.978.047c.826.034 1.675.056 2.5.056s1.674-.022 2.5-.056l.978-.047.939-.055.882-.06.808-.063a1.801 1.801 0 0 0 1.666-1.623C28.89 22.887 29 21.382 29 20c0-1.382-.11-2.887-.227-4.096a1.802 1.802 0 0 0-1.666-1.623l-.808-.062-.882-.06-.939-.056-.978-.047A60.763 60.763 0 0 0 21 14Zm-2 3.575a.598.598 0 0 1 .819-.559l.081.04 4.2 2.424a.602.602 0 0 1 .085.98l-.085.06-4.2 2.425a.6.6 0 0 1-.894-.43l-.006-.09v-4.85Z"
        fill="#D7DAE6"
      />
    </svg>
  );
}
