"use client";

import { ContentState, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import { useUpload } from "web/src/hooks/useUpload";

import { Text } from "../Text/Text";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Editor = dynamic(
  () => import("react-draft-wysiwyg").then((mod) => mod.Editor),
  { ssr: false }
);

interface IRichTextEditorProps {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export function RichTextEditor({
  label,
  placeholder,
  isRequired,
  value,
  onChange,
}: IRichTextEditorProps): JSX.Element {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { mutate: uploadAttachments } = useUpload();
  const customContentStateConverter = (
    contentState: ContentState
  ): ContentState => {
    // changes block type of images to 'atomic'
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block?.getEntityAt(0);
      if (entityKey && entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block?.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState as ContentState;
  };

  useEffect(() => {
    if (value) {
      const sanitizedValue = sanitizeHtml(value, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "iframe",
          "figure",
          "div",
          "img",
          "ol",
          "ul",
          "li",
          "strong",
          "video",
          "audio",
          "source",
          "track",
          "a",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "p",
          "br",
          "hr",
          "center",
          "small",
          "strike",
          "strong",
          "sub",
          "sup",
        ]),
        allowedAttributes: {
          "*": ["style", "class", "id", "data-*", "align", "src"],
          a: ["href", "name", "target"],
          iframe: [
            "src",
            "width",
            "height",
            "frameborder",
            "allow",
            "allowfullscreen",
          ],
          img: ["src", "alt", "width", "height"],
        },
        transformTags: {
          figure: sanitizeHtml.simpleTransform("div", {
            class: "wp-block-embed",
          }),
          div: sanitizeHtml.simpleTransform("div", {
            class: "wp-block-embed__wrapper",
          }),
        },
      });
      const contentState = stateFromHTML(sanitizedValue);
      const html = stateToHTML(editorState.getCurrentContent());
      if (html !== value) {
        setEditorState(
          EditorState.createWithContent(
            customContentStateConverter(contentState)
          )
        );
      }
    }
  }, [value]);

  const onUploadCallback = (
    fileForUpload: File
  ): Promise<{ data: { link: string } }> =>
    new Promise((resolve, reject) => {
      uploadAttachments(
        {
          data: {
            file: fileForUpload,
          },
        },
        {
          onSuccess: (successData) => {
            const imageSrc = `https://res.cloudinary.com/dv6hrcluu/${successData}`;
            resolve({ data: { link: imageSrc } });
          },
          onError: () => {
            reject();
          },
        }
      );
    });

  const onEditorStateChange = (newState: EditorState): void => {
    setEditorState(newState);
    const currentContent = newState.getCurrentContent();
    const html = stateToHTML(currentContent);
    if (onChange && html !== value) {
      onChange(html);
    }
  };

  return (
    <div>
      <div>
        <Text variant="small">
          {label}
          {isRequired && (
            <Text
              variant="small"
              intent="secondary"
              className="ml-1"
              align="left"
            >
              (required)
            </Text>
          )}
        </Text>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder={placeholder}
          editorClassName="min-h-[200px]"
          wrapperClassName="border rounded-xl"
          toolbarClassName="border-none"
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "textAlign",
              "colorPicker",
              "list",
              "link",
              "image",
            ],
            textAlign: {
              inDropdown: true,
              options: ["left", "center", "right", "justify"],
            },
            image: {
              uploadCallback: onUploadCallback,
              previewImage: true,
              inputAccept: "image/jpeg,image/jpg,image/png,image/webp",
            },
          }}
        />
      </div>
    </div>
  );
}
