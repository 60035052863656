import * as React from "react";
import { SVGProps } from "react";
export function ArrowUpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#arrow-up_svg__a)">
        <path
          d="m1 5 3.548-3.904a.278.278 0 0 1 .092-.07.255.255 0 0 1 .22 0c.035.016.066.04.092.07L8.5 5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="arrow-up_svg__a">
          <path fill="#fff" transform="matrix(1 0 0 -1 0 6)" d="M0 0h9v6H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
