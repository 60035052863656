"use client";

import { ChevronDownIcon, ChevronUpIcon } from "icons";
import { useState } from "react";

interface IAccordionProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

export const Accordion = ({
  title,
  children,
}: Readonly<IAccordionProps>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className="w-full bg-background-dark bg-opacity-20 text-left p-5 rounded-full focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <span>
            {isOpen ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </span>
        </div>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};
