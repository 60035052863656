import * as React from "react";
import { SVGProps } from "react";
export function ChatBubblesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#chat-bubbles_svg__a)"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.857 14.457.714 15l1.429-4.286V2.143A1.429 1.429 0 0 1 3.57.714H15a1.429 1.429 0 0 1 1.428 1.429v2.143" />
        <path d="m14.286 17.857 5 1.429L18.57 15V9.286a1.429 1.429 0 0 0-1.428-1.429H7.857a1.429 1.429 0 0 0-1.429 1.429v7.143a1.429 1.429 0 0 0 1.429 1.428h6.429Z" />
      </g>
      <defs>
        <clipPath id="chat-bubbles_svg__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
