import React from "react";

interface ISeparator {
  text?: string;
}

export function Separator({ text }: ISeparator): JSX.Element {
  return (
    <div className="relative w-full">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-black" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-black-500">{text}</span>
      </div>
    </div>
  );
}
