import * as React from "react";
import { SVGProps } from "react";
export function CircleMinusIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#circle-minus_svg__a)" fill="currentColor">
        <path d="M11.869 8.353H6.13A.63.63 0 0 0 5.513 9a.63.63 0 0 0 .647.619h5.737a.63.63 0 0 0 .619-.647c-.028-.338-.31-.619-.647-.619Z" />
        <path d="M9 .506A8.486 8.486 0 0 0 .506 9c0 4.697 3.825 8.522 8.522 8.522S17.55 13.697 17.55 9C17.522 4.303 13.697.506 9 .506Zm0 15.75c-3.994 0-7.228-3.262-7.228-7.256A7.226 7.226 0 0 1 9 1.772c3.994 0 7.256 3.262 7.256 7.256 0 3.966-3.262 7.228-7.256 7.228Z" />
      </g>
      <defs>
        <clipPath id="circle-minus_svg__a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
