import { VariantProps, cva } from "class-variance-authority";
import { ReactNode } from "react";
import { Placement } from "react-aria";
import { Dialog, Popover as AriaPopover } from "react-aria-components";

export interface IContentProps
  extends Omit<
    VariantProps<typeof PopoverContentClasses>,
    "isEntering" | "isExiting"
  > {
  placement?: Placement;
  containerPadding?: number;
  offset?: number;
  crossOffset?: number;
  shouldFlip?: boolean;
  children: ReactNode;
  className?: string;
}

export const PopoverContent = ({
  placement = "bottom",
  containerPadding = 0,
  offset = 0,
  crossOffset = 0,
  shouldFlip = true,
  children,
  className = "",
}: IContentProps): JSX.Element => (
  <AriaPopover
    placement={placement}
    containerPadding={containerPadding}
    offset={offset}
    crossOffset={crossOffset}
    shouldFlip={shouldFlip}
    className={({ isEntering, isExiting }) =>
      PopoverContentClasses({ isEntering, isExiting, className })
    }
  >
    <Dialog className="p-2 outline-none">{children}</Dialog>
  </AriaPopover>
);

const PopoverContentClasses = cva(
  "min-w-[120px] rounded drop-shadow-lg ring-1 ring-black/10 bg-white",
  {
    variants: {
      isEntering: {
        true: "animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 ease-out duration-200",
      },
      isExiting: {
        true: "animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 ease-in duration-100",
      },
    },
    defaultVariants: {
      isEntering: false,
      isExiting: false,
    },
  }
);
