import { cva } from "class-variance-authority";
import React, { ReactNode } from "react";
import { TabPanel as AriaTabPanel, TabPanelProps } from "react-aria-components";

export interface ITabpanelProps extends TabPanelProps {
  children: ReactNode;
  tabKey: string;
  className?: string;
}

export function TabPanel({
  children,
  tabKey,
  className,
}: ITabpanelProps): JSX.Element {
  return (
    <AriaTabPanel className={tabPanelClasses({ className })} id={tabKey}>
      {children}
    </AriaTabPanel>
  );
}
const tabPanelClasses = cva("flex flex-1 p-4");
