import { cva } from "class-variance-authority";
import React from "react";
import { Tab as AriaTab } from "react-aria-components";

export interface ITabProps {
  tabKey: string;
  tabName: string;
  className?: string;
}

export function Tab({ tabKey, tabName, className }: ITabProps): JSX.Element {
  return (
    <AriaTab id={tabKey} className={tabClasses({ className })}>
      {tabName}
    </AriaTab>
  );
}

const tabClasses = cva(
  "outline-0  data-[selected]:border-b-2 border-brand-primary cursor-pointer data-[disabled]:text-brand-body-secondary"
);
