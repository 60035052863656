"use client";

import { useFocusRing } from "@react-aria/focus";
import { useSwitch } from "@react-aria/switch";
import { useToggleState } from "@react-stately/toggle";
import { AriaSwitchProps } from "@react-types/switch";
import { cva, type VariantProps } from "class-variance-authority";
import React, { useRef } from "react";

export interface ISwitchProps
  extends AriaSwitchProps,
    VariantProps<typeof switchClass> {
  hideText?: boolean;
}

export const Switch: React.FC<ISwitchProps> = (props) => {
  const { size, hideText } = props;

  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useSwitch(props, state, ref);
  const { focusProps } = useFocusRing();

  const switchLabel = state.isSelected ? "Switch is on" : "Switch is off";

  return (
    <label
      data-testid="switch-test-id"
      className={switchClass({ size, isSwitchSelected: state.isSelected })}
    >
      <input
        {...inputProps}
        {...focusProps}
        ref={ref}
        aria-label={switchLabel}
        className="sr-only"
      />
      <span
        aria-hidden="true"
        className={knobClassName({ isSwitchSelected: state.isSelected })}
      />
      {!hideText && (
        <>
          <span className="absolute right-5 text-lg">YES</span>
          <span className="absolute left-5 text-lg">NO</span>
        </>
      )}
    </label>
  );
};
const knobClassName = cva(
  "flex items-center justify-center absolute w-1/2 h-full rounded-full shadow transition-transform duration-300 ease-in-out",
  {
    variants: {
      isSwitchSelected: {
        true: "translate-x-full bg-brand-primary",
        false: "translate-x-0 bg-gray-400",
      },
    },
    defaultVariants: {
      isSwitchSelected: false,
    },
  }
);

const switchClass = cva(
  "border relative inline-flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out",
  {
    variants: {
      size: {
        sm: "text-[6px] h-4 w-8",
        md: "h-10 w-[150px]",
      },
      isSwitchSelected: {
        true: "bg-brand-primary-10 border-brand-primary-light",
        false: "bg-gray-100 border-gray-400",
      },
    },
    defaultVariants: {
      size: "md",
      isSwitchSelected: false,
    },
  }
);

export default Switch;
