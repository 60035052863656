"use client";

import { ReactNode } from "react";
import type { RadioGroupProps } from "react-aria-components";
import { RadioGroup as AriaRadioGroup, Label } from "react-aria-components";

import { Radio } from "./Radio";

interface IRadioGroupProps extends Omit<RadioGroupProps, "children"> {
  children?: ReactNode;
  label?: string;
}

function RadioGroup({
  label,
  children,
  ...props
}: IRadioGroupProps): JSX.Element {
  return (
    <AriaRadioGroup {...props} className="flex flex-col gap-4">
      <Label>{label}</Label>
      {children}
    </AriaRadioGroup>
  );
}

RadioGroup.Radio = Radio;

export { RadioGroup, Radio };
