import * as React from "react";
import { SVGProps } from "react";
export function InstagramIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="instagram_svg__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={30}
        height={30}
      >
        <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
      </mask>
      <g mask="url(#instagram_svg__a)">
        <path
          d="M15.01.002c0 .001 0 .002-.002.002-6.262 0-8.093.005-8.45.036C5.274.146 4.47.349 3.602.785c-.67.336-1.2.72-1.725 1.262C.924 3.035.347 4.253.139 5.696c-.102.701-.133.847-.137 4.429-.004 1.196 0 2.766 0 4.875 0 6.26.009 8.091.035 8.448.106 1.253.3 2.039.715 2.9a6.325 6.325 0 0 0 4.098 3.348c.618.158 1.302.247 2.18.286.37.018 4.16.027 7.95.027s7.58-.005 7.941-.022c1.015-.049 1.606-.128 2.259-.296a6.279 6.279 0 0 0 4.098-3.353c.406-.838.614-1.659.706-2.845.022-.26.031-4.381.031-8.497 0-4.117-.009-8.233-.03-8.493-.093-1.204-.3-2.016-.724-2.872a5.914 5.914 0 0 0-1.284-1.76C26.984.922 25.771.344 24.324.137c-.701-.102-.843-.133-4.43-.137h-4.881a.002.002 0 0 0-.002.002Z"
          fill="url(#instagram_svg__b)"
        />
        <path
          d="M15.01.002c0 .001 0 .002-.002.002-6.262 0-8.093.005-8.45.036C5.274.146 4.47.349 3.602.785c-.67.336-1.2.72-1.725 1.262C.924 3.035.347 4.253.139 5.696c-.102.701-.133.847-.137 4.429-.004 1.196 0 2.766 0 4.875 0 6.26.009 8.091.035 8.448.106 1.253.3 2.039.715 2.9a6.325 6.325 0 0 0 4.098 3.348c.618.158 1.302.247 2.18.286.37.018 4.16.027 7.95.027s7.58-.005 7.941-.022c1.015-.049 1.606-.128 2.259-.296a6.279 6.279 0 0 0 4.098-3.353c.406-.838.614-1.659.706-2.845.022-.26.031-4.381.031-8.497 0-4.117-.009-8.233-.03-8.493-.093-1.204-.3-2.016-.724-2.872a5.914 5.914 0 0 0-1.284-1.76C26.984.922 25.771.344 24.324.137c-.701-.102-.843-.133-4.43-.137h-4.881a.002.002 0 0 0-.002.002Z"
          fill="url(#instagram_svg__c)"
        />
        <path
          d="M15.01.002c0 .001 0 .002-.002.002-6.262 0-8.093.005-8.45.036C5.274.146 4.47.349 3.602.785c-.67.336-1.2.72-1.725 1.262C.924 3.035.347 4.253.139 5.696c-.102.701-.133.847-.137 4.429-.004 1.196 0 2.766 0 4.875 0 6.26.009 8.091.035 8.448.106 1.253.3 2.039.715 2.9a6.325 6.325 0 0 0 4.098 3.348c.618.158 1.302.247 2.18.286.37.018 4.16.027 7.95.027s7.58-.005 7.941-.022c1.015-.049 1.606-.128 2.259-.296a6.279 6.279 0 0 0 4.098-3.353c.406-.838.614-1.659.706-2.845.022-.26.031-4.381.031-8.497 0-4.117-.009-8.233-.03-8.493-.093-1.204-.3-2.016-.724-2.872a5.914 5.914 0 0 0-1.284-1.76C26.984.922 25.771.344 24.324.137c-.701-.102-.843-.133-4.43-.137h-4.881a.002.002 0 0 0-.002.002Z"
          fill="url(#instagram_svg__d)"
        />
        <path
          d="M15.01.002c0 .001 0 .002-.002.002-6.262 0-8.093.005-8.45.036C5.274.146 4.47.349 3.602.785c-.67.336-1.2.72-1.725 1.262C.924 3.035.347 4.253.139 5.696c-.102.701-.133.847-.137 4.429-.004 1.196 0 2.766 0 4.875 0 6.26.009 8.091.035 8.448.106 1.253.3 2.039.715 2.9a6.325 6.325 0 0 0 4.098 3.348c.618.158 1.302.247 2.18.286.37.018 4.16.027 7.95.027s7.58-.005 7.941-.022c1.015-.049 1.606-.128 2.259-.296a6.279 6.279 0 0 0 4.098-3.353c.406-.838.614-1.659.706-2.845.022-.26.031-4.381.031-8.497 0-4.117-.009-8.233-.03-8.493-.093-1.204-.3-2.016-.724-2.872a5.914 5.914 0 0 0-1.284-1.76C26.984.922 25.771.344 24.324.137c-.701-.102-.843-.133-4.43-.137h-4.881a.002.002 0 0 0-.002.002Z"
          fill="url(#instagram_svg__e)"
        />
        <path
          d="M14.978 5.383c-2.619 0-2.947.011-3.976.057-1.025.046-1.727.21-2.34.445a4.79 4.79 0 0 0-1.708 1.108 4.68 4.68 0 0 0-1.113 1.705c-.24.613-.4 1.31-.446 2.334-.046 1.028-.057 1.355-.057 3.967 0 2.611.011 2.938.057 3.966.046 1.024.21 1.725.446 2.334a4.744 4.744 0 0 0 1.113 1.705 4.751 4.751 0 0 0 1.708 1.112c.613.236 1.315.4 2.34.445 1.03.046 1.357.057 3.976.057 2.618 0 2.946-.011 3.975-.057 1.026-.046 1.727-.21 2.34-.445a4.751 4.751 0 0 0 1.708-1.112 4.682 4.682 0 0 0 1.113-1.705c.236-.613.4-1.31.446-2.334.046-1.028.057-1.355.057-3.966 0-2.612-.011-2.943-.057-3.967-.05-1.024-.21-1.724-.446-2.334a4.745 4.745 0 0 0-1.113-1.705 4.659 4.659 0 0 0-1.707-1.108c-.614-.236-1.316-.4-2.34-.445-1.034-.046-1.358-.057-3.976-.057Zm-.866 1.732h.866c2.572 0 2.877.007 3.895.057.942.042 1.449.198 1.792.331.45.175.77.385 1.109.72.339.335.545.658.72 1.107.134.34.29.85.332 1.786.046 1.012.057 1.317.057 3.886 0 2.57-.011 2.87-.057 3.887-.042.937-.198 1.447-.332 1.785-.175.45-.385.77-.72 1.104-.34.335-.66.545-1.11.72-.339.133-.85.29-1.79.331-1.019.046-1.323.057-3.896.057s-2.878-.011-3.896-.057c-.941-.042-1.448-.202-1.791-.331a3.035 3.035 0 0 1-1.11-.72 2.939 2.939 0 0 1-.72-1.104c-.133-.338-.29-.848-.332-1.785-.045-1.013-.057-1.317-.057-3.887 0-2.57.008-2.87.057-3.886.042-.937.199-1.447.332-1.786a3.03 3.03 0 0 1 .72-1.107c.34-.335.66-.545 1.11-.72.339-.133.85-.29 1.791-.331.892-.046 1.235-.057 3.03-.057Zm6.011 1.599a1.153 1.153 0 1 0 0 2.306 1.153 1.153 0 1 0 0-2.306Zm-5.145 1.347a4.947 4.947 0 0 0-4.952 4.941 4.947 4.947 0 0 0 4.952 4.941 4.947 4.947 0 0 0 4.951-4.94 4.947 4.947 0 0 0-4.951-4.942Zm0 1.736a3.208 3.208 0 0 1 3.213 3.205 3.208 3.208 0 0 1-3.213 3.206 3.208 3.208 0 0 1-3.214-3.206 3.21 3.21 0 0 1 3.214-3.205Z"
          fill="#fff"
        />
      </g>
      <defs>
        <radialGradient
          id="instagram_svg__b"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(164.25 13.548 9.044) scale(19.1748 13.8146)"
        >
          <stop stopColor="#FF005F" />
          <stop offset={1} stopColor="#FC01D8" />
        </radialGradient>
        <radialGradient
          id="instagram_svg__c"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -23.9547 25.4154 0 7.972 32.325)"
        >
          <stop stopColor="#FC0" />
          <stop offset={0.124} stopColor="#FC0" />
          <stop offset={0.567} stopColor="#FE4A05" />
          <stop offset={0.694} stopColor="#FF0F3F" />
          <stop offset={1} stopColor="#FE0657" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="instagram_svg__d"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(4.9721 -8.56703 11.15345 6.47318 15.765 29.593)"
        >
          <stop stopColor="#FC0" />
          <stop offset={1} stopColor="#FC0" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="instagram_svg__e"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-18.25252 5.13949 -1.75099 -6.21851 4.07 1.23)"
        >
          <stop stopColor="#780CFF" />
          <stop offset={1} stopColor="#820BFF" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}
