import * as React from "react";
import { SVGProps } from "react";
export function ArrowDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#arrow-down_svg__a)">
        <path
          d="m1 1 3.548 3.904c.026.03.057.054.092.07a.255.255 0 0 0 .22 0 .278.278 0 0 0 .092-.07L8.5 1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="arrow-down_svg__a">
          <path fill="#fff" d="M0 0h9v6H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
