"use client";

import { VariantProps, cva } from "class-variance-authority";
import { CloseIcon } from "icons";
import React, { ReactNode, useEffect, useState } from "react";

export interface IInfoMessage extends VariantProps<typeof infoMessageClass> {
  children: ReactNode;
  isShow?: boolean;
  autoClosAble?: boolean;
  onClickClose?: () => void;
}

export function InfoMessage({
  children,
  isShow = false,
  autoClosAble = true,
  intent,
  onClickClose,
}: IInfoMessage): JSX.Element {
  const [isShowMessage, setIsShowMessage] = useState<boolean>(isShow);

  useEffect(() => {
    setIsShowMessage(isShow);
  }, [isShow]);

  return (
    <div>
      {isShowMessage && (
        <div className={infoMessageClass({ intent })}>
          {children}
          {(onClickClose || autoClosAble) && (
            <button
              type="button"
              aria-label="close-resend-email-message"
              className="cursor-pointer"
              onClick={() => {
                setIsShowMessage(false);
                onClickClose?.();
              }}
            >
              <CloseIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

const infoMessageClass = cva(
  "flex flex-row gap-5 items-center justify-between p-4 rounded-lg font-medium text-center ",
  {
    variants: {
      intent: {
        primary: ["bg-brand-primary"],
        secondary: ["bg-brand-secondary"],
        blue: ["bg-brand-blue text-white"],
        danger: ["bg-brand-danger text-white"],
        success: ["bg-brand-success"],
        warning: ["bg-brand-warning"],
        info: ["bg-brand-white"],
      },
    },
    defaultVariants: {
      intent: "primary",
    },
  }
);
