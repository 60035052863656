import * as React from "react";
import { SVGProps } from "react";
export function BellNotificationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.769 16h2.307M13.047 6.125a5.125 5.125 0 0 0-10.25 0v5.092c0 .51-.203.998-.563 1.359-.36.36-.724.563-1.234.563h13.844c-.51 0-.873-.203-1.234-.563-.36-.36-.563-.85-.563-1.36v-5.09Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
