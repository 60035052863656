import * as React from "react";
import { SVGProps } from "react";
export function CurrentCompanyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.154 11.577C22.154 5.735 17.418 1 11.577 1 5.735 1 1 5.735 1 11.577c0 5.841 4.735 10.577 10.577 10.577"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.985 9.654a4.809 4.809 0 1 0-6.331 6.33M26 23.078l-3.846-3.847 1.923-1.923a.96.96 0 0 0-.481-1.616l-8.905-2.202a.96.96 0 0 0-1.163 1.164l2.202 8.904a.962.962 0 0 0 1.616.443l1.923-1.924 3.846 3.847L26 23.078Z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
