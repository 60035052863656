import * as React from "react";
import { SVGProps } from "react";

export function FileOpenIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="empty-file">
                <path
                    id="Vector"
                    d="M18.5629 0.675049H5.43789C4.16289 0.675049 3.15039 1.68755 3.15039 2.96255V15C3.15039 15.5625 3.37539 16.0875 3.78789 16.4625L10.5379 22.8375C10.9129 23.2125 11.4004 23.4 11.9254 23.4H18.5254C19.8004 23.4 20.8129 22.3875 20.8129 21.1125V2.96255C20.8504 1.68755 19.8004 0.675049 18.5629 0.675049ZM6.07539 16.275H10.4629C10.6504 16.275 10.8004 16.425 10.8004 16.575V20.7001L6.07539 16.275ZM19.1629 21.0375C19.1629 21.375 18.9004 21.6376 18.5629 21.6376H12.4879V16.575C12.4879 15.4875 11.5879 14.5875 10.4629 14.5875H4.83789V2.96255C4.83789 2.62505 5.10039 2.36255 5.43789 2.36255H18.5254C18.8629 2.36255 19.1254 2.62505 19.1254 2.96255V21.0375H19.1629Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
