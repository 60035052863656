"use client";

import { cva, type VariantProps } from "class-variance-authority";
import NextLink from "next/link";
import { AnchorHTMLAttributes } from "react";
import { useCurrentLocale } from "web/src/hooks/useCurrentLocale";

export interface ILinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkClass> {
  children: string | JSX.Element;
  href: string;
  isAbsolute?: boolean;
  style?: { [key: string]: string | undefined };
}

export function Link({
  intent,
  className,
  fontSize,
  decoration,
  children,
  href,
  hasHoverEffect,
  isAbsolute = false,
  target,
  style,
}: Readonly<ILinkProps>): JSX.Element {
  const currentLocale = useCurrentLocale();

  return (
    <NextLink
      style={style}
      target={target}
      className={linkClass({
        intent,
        fontSize,
        decoration,
        className,
        hasHoverEffect,
      })}
      href={
        isAbsolute
          ? href
          : `/${currentLocale}/${
              href.startsWith("/") ? href.replace("/", "") : href
            }`
      }
    >
      {children}
    </NextLink>
  );
}

const linkClass = cva("cursor-pointer transition-colors duration-100 ease-in", {
  variants: {
    intent: {
      primary: "text-black",
      secondary: "text-secondary",
    },
    fontSize: {
      sm: "text-xs",
      base: "text-sm",
      md: "text-base",
      lg: "text-lg",
    },
    weight: {
      normal: ["font-normal"],
      medium: ["font-medium"],
    },
    decoration: {
      true: "underline underline-offset-4 decoration-1 whitespace-nowrap",
      false: "decoration-none",
    },
    hasHoverEffect: {
      true: " hover:text-primary",
      false: "",
    },
  },
  defaultVariants: {
    intent: "primary",
    fontSize: "base",
    weight: "medium",
    decoration: true,
    hasHoverEffect: true,
  },
});
