const i18nConfig = {
  locales: ["en", "fr", "es"],
  defaultLocale: "en",
  i18nNamespaces: [
    "home",
    "about",
    "nav",
    "footer",
    "pages",
    "validation",
    "not-found",
    "signin",
    "password-reset",
  ],
  localeDetection: true,
  localePath: {
    defaultLocale: "always",
  },
};

module.exports = i18nConfig;
