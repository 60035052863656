import * as React from "react";
import { SVGProps } from "react";
export function ChevronFillUpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m5 .5-5 5h10l-5-5Z" fill="currentColor" />
    </svg>
  );
}
