import * as React from "react";
import { SVGProps } from "react";
export function AttachmentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.539 17.923V4.846A3.847 3.847 0 0 0 8.692 1H4.846A3.846 3.846 0 0 0 1 4.846v13.077A3.077 3.077 0 0 0 4.077 21h1.538a3.077 3.077 0 0 0 3.077-3.077V6.385a1.539 1.539 0 0 0-1.538-1.539h-.77a1.539 1.539 0 0 0-1.538 1.539v8.461"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
