"use client";

import React, { ReactNode } from "react";
import { Button as AriaButton, type ButtonProps } from "react-aria-components";

import { Spinner } from "../spinner/Spinner";

export interface IIconButtonProps extends ButtonProps {
  children: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isLoading?: boolean;
}

export function IconButton({
  children,
  leftIcon,
  rightIcon,
  isLoading = false,
  ...props
}: IIconButtonProps): JSX.Element {
  return (
    <AriaButton
      className="bg-primary w-full flex items-center justify-center rounded-full h-[50px] relative text-black"
      {...props}
    >
      {leftIcon && (
        <div className="bg-white absolute left-1.5 top-[5px] rounded-full w-10 h-10 flex items-center justify-center">
          {leftIcon}
        </div>
      )}
      <div className="flex gap-2 justify-center items-center">
        {isLoading ? <Spinner /> : children}
      </div>
      {rightIcon && (
        <div className="bg-white absolute right-1.5 top-[5px] rounded-full w-10 h-10 flex items-center justify-center">
          {rightIcon}
        </div>
      )}
    </AriaButton>
  );
}
