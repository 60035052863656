"use client";

/* eslint-disable no-nested-ternary */
import { VariantProps, cva } from "class-variance-authority";
import { CheckCircleIcon, DeleteCircleIcon } from "icons";
import React from "react";
import { Label, Input } from "react-aria-components";
import { UseFormRegisterReturn } from "react-hook-form";

import { Text } from "../Text/Text";

export interface ITextFieldProps
  extends VariantProps<typeof textFieldWrapperClass> {
  testId?: string;
  label?: string;
  placeholder?: string;
  type?: "text" | "email" | "number" | "date" | "time" | "color";
  errorMessage?: string;
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  hasValue?: boolean;
  value?: string;
  onChangeText?: (text: string) => void;
  register?: UseFormRegisterReturn<string>;
}

export function TextField({
  testId = "input-test-id",
  label,
  placeholder,
  type = "text",
  isDisabled = false,
  isRequired = false,
  hasValue = false,
  register,
  errorMessage = "",
  helperMessage = "",
  value,
  onChangeText,
  intent,
}: ITextFieldProps): JSX.Element {
  let calculatedIntent: "primary" | "success" | "warning" | "danger";

  if (intent) {
    calculatedIntent = intent;
  } else if (errorMessage) {
    calculatedIntent = "danger";
  } else if (hasValue) {
    calculatedIntent = "success";
  } else {
    calculatedIntent = "primary";
  }

  return (
    <div className="flex flex-col gap-1 flex-1">
      <Label>
        <Text variant="small" align="left">
          {label}
          {isRequired && (
            <Text
              variant="small"
              intent="secondary"
              className="ml-1"
              align="left"
            >
              (required)
            </Text>
          )}
        </Text>
        <div className={textFieldWrapperClass({ intent: calculatedIntent })}>
          <Input
            data-testid={testId}
            type={type}
            disabled={isDisabled}
            className={textFieldClass({ type })}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChangeText?.(e.target.value)}
            {...register}
          />
          {calculatedIntent === "success" && (
            <CheckCircleIcon
              className={iconsClass({ type, intent: "success" })}
            />
          )}
          {calculatedIntent === "danger" && (
            <DeleteCircleIcon
              className={iconsClass({ type, intent: "danger" })}
            />
          )}
        </div>
      </Label>

      {errorMessage ? (
        <Text variant="small" intent={calculatedIntent} align="left">
          {errorMessage}
        </Text>
      ) : (
        <Text variant="small" intent={calculatedIntent} align="left">
          {helperMessage}
        </Text>
      )}
    </div>
  );
}

const textFieldWrapperClass = cva(
  "flex items-center border rounded-main hover:border-primary focus-within:border-primary relative h-10",
  {
    variants: {
      intent: {
        primary: "border-background-dark",
        success: "border-background-dark",
        warning: "border-warning",
        danger: "border-danger",
      },
    },
    defaultVariants: {
      intent: "primary",
    },
  }
);

const textFieldClass = cva(
  "rounded-main outline-none p-2 text-sm font-medium border-none flex-1",
  {
    variants: {
      type: {
        text: "",
        email: "",
        number: "mr-4",
        date: "",
        time: "",
        color: "!p-0",
      },
      defaultVariants: {
        type: "text",
      },
    },
  }
);

const iconsClass = cva(
  "absolute w-4 h-4 right-4  flex items-center pointer-events-none",
  {
    variants: {
      intent: {
        primary: "text-gray-400 ",
        success: "text-success",
        warning: "text-warning",
        danger: "text-danger",
      },
      type: {
        text: "",
        email: "",
        number: "mr-4",
        date: "mr-4",
        time: "mr-4",
        color: "",
      },
    },
    defaultVariants: {
      intent: "primary",
      type: "text",
    },
  }
);
