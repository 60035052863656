import * as React from "react";
import { SVGProps } from "react";
export function XNoColorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={41} height={41} rx={2} fill="silver" fillOpacity={0.2} />
      <path
        d="m22.941 18.258 7.595-9.633h-1.8l-6.593 8.364-5.268-8.364H10.8l7.965 12.649L10.8 31.375h1.8l6.963-8.834 5.564 8.834h6.075L22.94 18.258Zm-2.464 3.127-.808-1.26-6.421-10.021h2.764l5.181 8.087.807 1.26 6.737 10.514h-2.764l-5.496-8.58Z"
        fill="#7B83AC"
        fillOpacity={0.3}
      />
    </svg>
  );
}
