"use client";

import { parseDate, CalendarDate } from "@internationalized/date";
import { VariantProps, cva } from "class-variance-authority";
import { CalendarIcon, CheckCircleIcon, DeleteCircleIcon } from "icons";
import React, { useMemo, useState } from "react";
import {
  I18nProvider,
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker as AriaDatePicker,
  DateSegment,
  Group,
  Heading,
  Label,
  Popover,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarGridBody,
  ValidationResult,
  Dialog,
} from "react-aria-components";

import { Text } from "../Text/Text";

export interface IDatePicker extends VariantProps<typeof DateInputClasses> {
  selectDate?: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onSelectNewDate?: (newData: string) => void;
}

export function DatePicker({
  selectDate,
  minDate,
  maxDate,
  label,
  isRequired = false,
  errorMessage = "",
  helperMessage = "",
  isDisabled,
  intent = "primary",
  onSelectNewDate,
  ...props
}: IDatePicker): JSX.Element {
  const [internalDataErrorsMessage, setInternalDataErrorsMessage] = useState<
    string | undefined
  >();
  const handleSelectNewData = (newSelectedData: CalendarDate): void => {
    onSelectNewDate?.(newSelectedData?.toString());
  };
  const minDateAllowed = useMemo(() => {
    try {
      return minDate ? parseDate(minDate) : undefined;
    } catch (error) {
      setInternalDataErrorsMessage("Invalid min date");
      return undefined;
    }
  }, [minDate]);

  const maxDateAllowed = useMemo(() => {
    try {
      return maxDate ? parseDate(maxDate) : undefined;
    } catch (error) {
      setInternalDataErrorsMessage("Invalid max date");
      return undefined;
    }
  }, [maxDate]);
  const DateValue = useMemo(() => {
    try {
      if (selectDate) {
        const parseSelectedDate = parseDate(selectDate);
        setInternalDataErrorsMessage(undefined);
        return parseSelectedDate;
      }
      return null;
    } catch (error) {
      setInternalDataErrorsMessage("Invalid date");
      return null;
    }
  }, [selectDate]);

  return (
    <I18nProvider locale="en-UK">
      <AriaDatePicker
        className="flex flex-col"
        {...props}
        value={DateValue}
        minValue={minDateAllowed}
        maxValue={maxDateAllowed}
        onChange={(newSelectedData) => handleSelectNewData(newSelectedData)}
        isDisabled={isDisabled}
      >
        <Label>
          <Text variant="small">
            {label}
            {isRequired && (
              <Text variant="small" intent="secondary" className="ml-1">
                (required)
              </Text>
            )}
          </Text>
        </Label>
        <Group
          isDisabled={isDisabled}
          className={DateInputClasses({
            intent,
            disable: isDisabled,
            hasError: !!errorMessage,
          })}
        >
          <DateInput className="flex">
            {(segment) => (
              <DateSegment
                segment={segment}
                className="outline-offset-2 outline-brand-secondary"
              />
            )}
          </DateInput>
          <Button
            isDisabled={isDisabled}
            className="outline-offset-4 outline-brand-secondary"
          >
            {intent === "success" && (
              <CheckCircleIcon className={iconsClass({ intent: "success" })} />
            )}
            {intent === "danger" && (
              <DeleteCircleIcon className={iconsClass({ intent: "danger" })} />
            )}
            {intent === "primary" && (
              <CalendarIcon className={iconsClass({ intent: "primary" })} />
            )}
          </Button>
        </Group>
        {internalDataErrorsMessage && (
          <Text variant="small" intent="danger">
            {internalDataErrorsMessage}
          </Text>
        )}
        {errorMessage ? (
          <Text variant="small" intent="danger">
            {errorMessage.toString()}
          </Text>
        ) : (
          <Text variant="small" intent={intent}>
            {helperMessage}
          </Text>
        )}
        <Popover>
          <Dialog>
            <Calendar className="bg-white shadow-lg p-5">
              <header className="flex w-full justify-center gap-x-5 mb-4">
                <Button
                  slot="previous"
                  className="data-[disabled]:text-gray-300"
                >
                  ◀
                </Button>
                <Heading className="text-lg w-40 text-center" />
                <Button slot="next" className="data-[disabled]:text-gray-300">
                  ▶
                </Button>
              </header>
              <CalendarGrid>
                <CalendarGridHeader>
                  {(day) => (
                    <CalendarHeaderCell className="text-primary">
                      {day}
                    </CalendarHeaderCell>
                  )}
                </CalendarGridHeader>
                <CalendarGridBody>
                  {(date) => (
                    <CalendarCell date={date} className={calendarCell()} />
                  )}
                </CalendarGridBody>
              </CalendarGrid>
            </Calendar>
          </Dialog>
        </Popover>
      </AriaDatePicker>
    </I18nProvider>
  );
}

const DateInputClasses = cva(
  "w-full flex items-center gap-x-4 justify-between border rounded hover:border-primary relative p-2 px-3 outline-none text-sm font-medium text-body-secondary",
  {
    variants: {
      intent: {
        primary: "border-background-light",
        success: "border-background-dark",
        warning: "border-warning",
        danger: "border-danger",
      },
      disable: {
        true: "cursor-not-allowed bg-background-light border-background-dark",
      },
      hasError: {
        true: "border-danger",
      },
    },
    defaultVariants: {
      intent: "primary",
      hasError: false,
    },
  }
);

const calendarCell = cva(
  "w-9 h-9  cursor-pointer data-[focused]:outline-2 data-[disabled]:text-gray-300  outline-brand-secondary rounded-full flex items-center justify-center outside-month:text-gray-300 hover:bg-primaryLight selected:bg-primary selected:text-white "
);

const iconsClass = cva("flex items-center pointer-events-none w-4 h-4", {
  variants: {
    intent: {
      primary: "text-body-primary ",
      success: "text-success",
      warning: "text-warning",
      danger: "text-danger",
    },
  },
  defaultVariants: {
    intent: "primary",
  },
});
