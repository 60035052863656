import * as React from "react";
import { SVGProps } from "react";
export function CaretDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m14 6-4 8-4-8h8Z" fill="currentColor" />
    </svg>
  );
}
