import * as React from "react";
import { SVGProps } from "react";
export function InformationCicleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#information-cicle_svg__a)"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7 13.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13ZM5.5 10h3" />
        <path d="M7 10V6.5H6M7 4.25a.25.25 0 0 1 0-.5M7 4.25a.25.25 0 0 0 0-.5" />
      </g>
      <defs>
        <clipPath id="information-cicle_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
