import axios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
} from "axios";

import { CookieKeys } from "@/enums/CookieKeys.enum";
import {
  getCookieUtil,
  setCookieUtil,
  deleteCookieUtil,
} from "@/utils/cookies";

export const api = axios.create({
  baseURL: `${process.env.API_URL}`,
});

export const customClient = async <TResponse>({
  data,
  url,
  method,
  params,
  headers,
  onUploadProgress,
}: {
  data?: unknown;
  url: string;
  signal?: AbortSignal;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  params?: Record<string, string>;
  headers?: { "Content-Type": string };
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}): Promise<TResponse> => {
  const queryParams = params ? `?${new URLSearchParams(params)}` : "";
  const { data: response } = await api(`${url}${queryParams}`, {
    method,
    data,
    headers: {
      Authorization: `Bearer ${getCookieUtil(CookieKeys.AccessToken)}`,
      ...headers,
    },
    onUploadProgress:
      method === "POST" || method === "PUT" || method === "PATCH"
        ? onUploadProgress
        : undefined,
  });

  return response;
};

async function handleSuccess(response: AxiosResponse): Promise<AxiosResponse> {
  return response;
}

async function refreshToken(): Promise<string> {
  const response = await axios.post(
    `${process.env.API_URL}/auth/refresh-token`,
    {
      refreshToken: getCookieUtil(CookieKeys.RefreshToken),
    }
  );
  const { accessToken } = response.data;
  setCookieUtil({ key: CookieKeys.AccessToken, value: accessToken });
  return accessToken;
}

async function handleError(error: AxiosError): Promise<unknown> {
  if (error.response?.status === 401 && error.config) {
    try {
      const newAccessToken = await refreshToken();
      const newConfig: AxiosRequestConfig = {
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      setCookieUtil({ key: CookieKeys.AccessToken, value: newAccessToken });

      return axios.request(newConfig);
    } catch (refreshError) {
      const axiosError = refreshError as AxiosError;

      if (
        axiosError.response?.status === 401 &&
        window.location.pathname !== "/signin" &&
        !window.location.pathname.includes("verify-account")
      ) {
        deleteCookieUtil(CookieKeys.AccessToken);
        deleteCookieUtil(CookieKeys.RefreshToken);
        window.location.href = "/signin";
        return Promise.reject(refreshError);
      }

      throw refreshError;
    }
  }

  if (error.response) {
    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
}

api.interceptors.response.use(handleSuccess, handleError);
