import { cva, type VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";

export type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface IHeadingProps extends VariantProps<typeof textClass> {
  children: string | JSX.Element | ReactNode;
  className?: string;
  variant?: HeadingTag;
  id?: string;
}

export function Heading({
  variant = "h1",
  intent,
  align,
  className,
  children,
  id = "",
}: IHeadingProps): JSX.Element {
  const headingClass = textClass({ variant, intent, className, align });
  if (variant === "h1") {
    return (
      <h1 id={id} className={headingClass}>
        {children}
      </h1>
    );
  }
  if (variant === "h2") {
    return (
      <h2 id={id} className={headingClass}>
        {children}
      </h2>
    );
  }
  if (variant === "h3") {
    return (
      <h3 id={id} className={headingClass}>
        {children}
      </h3>
    );
  }
  if (variant === "h4") {
    return (
      <h4 id={id} className={headingClass}>
        {children}
      </h4>
    );
  }
  if (variant === "h5") {
    return (
      <h4 id={id} className={headingClass}>
        {children}
      </h4>
    );
  }
  return (
    <h1 id={id} className={headingClass}>
      {children}
    </h1>
  );
}

const textClass = cva(
  [""], // Base class for the component
  {
    variants: {
      intent: {
        themePrimary: ["text-primary"],
        "brand-primary": "text-primary",
        primary: "text-brand-body-primary",
        secondary: "text-brand-body-secondary",
        blue: "text-brand-blue",
        danger: "text-brand-danger",
        success: "text-brand-success",
        warning: "text-brand-warning",
        white: "text-white",
      },
      variant: {
        h1: ["text-2xl lg:text-3xl font-black"],
        h2: ["text-[22px] lg:text-2xl font-bold"],
        h3: ["text-lg lg:text-xl font-bold"],
        h4: ["text-md lg:text-lg font-bold"],
        h5: ["text-base lg:text-md font-bold"],
        h6: ["text-sm lg:text-base font-bold"],
      },
      align: {
        center: "text-center",
        left: "text-left",
        right: "text-right",
      },
    },
    defaultVariants: {
      variant: "h1",
      intent: "primary",
      align: "left",
    },
  }
);
