import * as React from "react";
import { SVGProps } from "react";
export function ExpandProfessionalNetworkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 56 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#expand-professional-network_svg__a)" fill="#000">
        <path d="M56 18.819c0-3.322-2.68-6.016-5.987-6.016a5.93 5.93 0 0 0-4.506 2.065L33.674 7.98c.204-.604.319-1.258.319-1.938 0-3.322-2.68-6.016-5.987-6.016-3.306 0-5.986 2.694-5.986 6.016 0 .68.114 1.322.319 1.924l-11.833 6.89A5.974 5.974 0 0 0 6 12.79c-3.307 0-5.988 2.694-5.988 6.017a6.02 6.02 0 0 0 4.698 5.875l.013 13.496A6.027 6.027 0 0 0 0 44.052c0 3.323 2.68 6.017 5.987 6.017 1.761 0 3.344-.77 4.442-1.988L22.3 54.97a5.957 5.957 0 0 0-.294 1.86c0 3.322 2.68 6.016 5.987 6.016 3.306 0 5.986-2.694 5.986-6.017 0-.654-.102-1.27-.293-1.86l11.871-6.888A5.963 5.963 0 0 0 50 50.069c3.306 0 5.987-2.694 5.987-6.017 0-2.912-2.08-5.362-4.825-5.9V24.708C53.92 24.182 56 21.744 56 18.819Zm-5.987-3.694c2.03 0 3.676 1.654 3.676 3.694a3.686 3.686 0 0 1-3.676 3.694 3.686 3.686 0 0 1-3.676-3.694c0-2.04 1.66-3.694 3.676-3.694ZM28.006 2.36c2.03 0 3.676 1.655 3.676 3.695A3.674 3.674 0 0 1 31.25 7.8c-.026.025-.038.051-.064.09l-.039.076a3.67 3.67 0 0 1-3.14 1.784c-2.03 0-3.676-1.655-3.676-3.695s1.647-3.695 3.676-3.695ZM2.323 18.82A3.687 3.687 0 0 1 6 15.125c2.03 0 3.676 1.654 3.676 3.694S8.029 22.513 6 22.513a3.686 3.686 0 0 1-3.677-3.694ZM6 47.759a3.687 3.687 0 0 1-3.677-3.694A3.687 3.687 0 0 1 6 40.371c2.03 0 3.676 1.655 3.676 3.694-.013 2.04-1.66 3.695-3.676 3.695Zm22.006 12.765c-2.03 0-3.676-1.655-3.676-3.695s1.647-3.694 3.676-3.694c2.03 0 3.676 1.655 3.676 3.694 0 2.04-1.646 3.695-3.676 3.695Zm4.557-7.581a5.968 5.968 0 0 0-4.557-2.13c-1.825 0-3.459.821-4.557 2.13l-11.82-6.85a6.014 6.014 0 0 0-4.582-7.954l-.014-13.406c2.809-.488 4.953-2.963 4.953-5.927 0-.68-.115-1.334-.319-1.937L23.5 9.98a5.945 5.945 0 0 0 4.519 2.066c1.8 0 3.408-.796 4.506-2.066l11.833 6.89a6.038 6.038 0 0 0-.319 1.936c0 2.912 2.08 5.362 4.825 5.902v13.444c-2.744.538-4.825 2.976-4.825 5.9 0 .719.128 1.399.357 2.027l-11.833 6.864Zm21.126-8.877c0 2.039-1.646 3.694-3.676 3.694-2.03 0-3.676-1.655-3.676-3.694a3.687 3.687 0 0 1 3.676-3.695c2.03 0 3.676 1.655 3.676 3.694Z" />
        <path d="M33.585 36.086V31.89a3.624 3.624 0 0 0 1.097-2.617V23.77c0-3.374-2.731-6.119-6.089-6.119H27.42c-3.357 0-6.089 2.745-6.089 6.119v5.503c0 1.001.409 1.937 1.098 2.617v4.195c-1.328.693-4.519 2.45-7.506 4.913a1.166 1.166 0 0 0-.153 1.643c.409.5 1.136.564 1.634.153 3.306-2.732 6.88-4.541 7.57-4.874.485-.244.779-.719.779-1.27V31.34c0-.385-.192-.757-.51-.962a1.31 1.31 0 0 1-.589-1.104V23.77c0-2.091 1.698-3.798 3.78-3.798h1.174c2.08 0 3.778 1.707 3.778 3.798v5.503c0 .437-.217.847-.587 1.104-.32.218-.511.577-.511.962v5.31c0 .54.306 1.027.791 1.27.69.334 4.264 2.156 7.57 4.875a1.137 1.137 0 0 0 1.621-.153c.409-.5.332-1.233-.153-1.643-3.012-2.463-6.203-4.233-7.531-4.913Z" />
      </g>
      <defs>
        <clipPath id="expand-professional-network_svg__a">
          <path fill="#fff" d="M0 0h56v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
