"use client";

import React, { ReactNode } from "react";

interface IBadge {
  children: ReactNode;
}

export function Badge({ children }: Readonly<IBadge>): JSX.Element {
  return (
    <div
      data-testid="badge-test-id"
      className="rounded-3xl px-2.5 py-1 border border-black border-1 text-sm flex items-center justify-center whitespace-nowrap"
    >
      {children}
    </div>
  );
}
