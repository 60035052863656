"use client";

import React, { ReactNode } from "react";
import { Tabs as AriaTabs, TabsProps } from "react-aria-components";

import { Tab } from "./Tab";
import { TabList } from "./TabList";
import { TabPanel } from "./TabPanel";

export interface ITabsProps extends TabsProps {
  children: ReactNode;
  className?: string;
}

function Tabs({ children, className, ...props }: ITabsProps): JSX.Element {
  return (
    <AriaTabs {...props} className={className}>
      {children}
    </AriaTabs>
  );
}

Tabs.Tab = Tab;
Tabs.TabPanel = TabPanel;
Tabs.TabList = TabList;

export { Tabs, Tab, TabList, TabPanel };
