import * as React from "react";
import { SVGProps } from "react";
export function PencilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.512 2.488a1.667 1.667 0 0 0-2.357 0L2 11.643v2.38h2.333l9.179-9.178c.65-.65.65-1.706 0-2.357Z"
        fill="#6B7280"
      />
      <path
        d="m13.512 2.488.353-.353-.353.353ZM4.333 14.024v.5a.5.5 0 0 0 .354-.147l-.354-.353Zm-2.333 0h-.5a.5.5 0 0 0 .5.5v-.5Zm0-2.381-.353-.354a.5.5 0 0 0-.147.354H2Zm9.508-8.801a1.167 1.167 0 0 1 1.65 0l.707-.707a2.167 2.167 0 0 0-3.064 0l.707.707Zm1.65 0a1.167 1.167 0 0 1 0 1.65l.707.707a2.167 2.167 0 0 0 0-3.064l-.707.707Zm0 1.65L3.98 13.67l.707.707L13.865 5.2l-.707-.707Zm-8.825 9.032H2v1h2.333v-1Zm6.468-11.39L1.647 11.29l.707.707 9.154-9.154-.707-.707Zm-9.3 9.509v2.38h1v-2.38h-1Zm8.3-7.801 2.357 2.357.707-.707-2.357-2.357-.707.707Z"
        fill="#111827"
      />
    </svg>
  );
}
