import * as React from "react";
import { SVGProps } from "react";
export function CheckBoxIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={14}
        height={14}
        rx={4.5}
        stroke="currentColor"
      />
      <path
        d="m11 5-4 5-2-1.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
