import * as React from "react";
import { SVGProps } from "react";
export function DiscoverCommunityIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={334}
      height={150}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M109.466 121.456c29.846 0 54.041-24.215 54.041-54.086s-24.195-54.087-54.041-54.087c-29.845 0-54.04 24.216-54.04 54.087 0 29.871 24.195 54.086 54.04 54.086Z"
        fill="#FF8702"
      />
      <path
        d="M313.9 40.386H155.022a5.35 5.35 0 0 0-4.785 2.96l-47.465 95.011H5.834a5.819 5.819 0 0 0-5.817 5.821A5.82 5.82 0 0 0 5.834 150h255.971a9.845 9.845 0 0 0 8.864-5.566l2.68-5.55 45.336-90.749c1.779-3.56-.809-7.748-4.785-7.748Z"
        fill="#fff"
      />
      <path
        d="M310.056 48.12H156.491l-44.645 90.091h153.566l44.644-90.092Z"
        fill="#0E93DE"
      />
      <path
        d="M77.874 138.163h35.678v3.753a3.428 3.428 0 0 1-3.425 3.428H81.3a3.428 3.428 0 0 1-3.425-3.428v-3.753Z"
        fill="#C6C4C4"
      />
      <path
        d="M124.553 51.098 107.45 87.433l-3.778-12.811-19.096 17.327-4.617-4.936 19.517-17.537L86.57 64.96l37.983-13.862Z"
        fill="#fff"
      />
      <mask
        id="discover-community_svg__a"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={111}
        y={0}
        width={223}
        height={139}
      >
        <path
          d="M333.983 0H156.491l-44.645 138.211h153.566L333.983 0Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#discover-community_svg__a)">
        <path
          d="M203.978 50.731c1.243 1.356 3.351 3.105 5.731 2.767.078-2.533-2.052-5.401-3.648-6.748a20.42 20.42 0 0 0-.75-.602l-1.423 1.762a2.163 2.163 0 0 0 .092 2.821h-.002ZM202.948 93.49a2.78 2.78 0 0 0-2.766 1.012l-.552.711c2.088.782 4.442 1.915 6.457 1.605l.197-.033a4.629 4.629 0 0 0 2.148-1.029c-1.086-1.17-3.714-1.894-5.484-2.264v-.002Z"
          fill="#FF8702"
        />
        <path
          d="M126.141 169.711s30.639-123.812 30.642-123.81l4.256-20.031a3.292 3.292 0 1 1 6.439 1.37l-4.41 20.483.472.137 5.528-25.498a4.127 4.127 0 0 1 4.939-3.115 4.131 4.131 0 0 1 3.113 4.943l-5.339 26.062.579.168 6.123-30.087a5.117 5.117 0 0 1 5.867-3.433l.332.066c3.149.63 4.657 3.97 3.446 6.946l-5.523 24.38c8.025-14.812 18.978-6.52 22.702-2.148l-1.422 1.763a2.163 2.163 0 0 0 .092 2.82c1.242 1.357 3.351 3.105 5.73 2.768a3.692 3.692 0 0 1-.473 1.742c-1.046 1.83-5.366 1.274-6.595.147-2.948-2.707-6.038-5.236-10.248-3.812-3.686 1.247-6.135 5.237-7.424 8.715-.952 2.572-1.267 5.423-.601 8.084 1.268 5.075 5.665 8.657 9.67 11.639 4.226 3.144 8.855 5.694 13.54 8.074 3.166 1.606 3.175 5.68.854 7.669-1.085-1.171-3.714-1.894-5.483-2.264a2.781 2.781 0 0 0-2.767 1.011l-.551.711a16.92 16.92 0 0 0-1.317-.442c-2.492-.7-5.046-1.234-7.632-1.407-5.009-.335-10.177.91-13.173 5.253 0 0-13.217 37.27-20.629 70.457"
          fill="#7F4036"
        />
        <path
          d="m180.016 20.947-.284 1.121-.195.775a1.812 1.812 0 0 0 1.31 2.2l3.733.945a1.81 1.81 0 0 0 2.198-1.313l.195-.775.306-1.21a3.74 3.74 0 0 0-2.846-4.572c-1.979-.422-3.921.866-4.417 2.83ZM170.11 22.952l-.238.942-.164.65a1.52 1.52 0 0 0 1.1 1.848l3.135.792a1.52 1.52 0 0 0 1.846-1.102l.164-.65.258-1.017a3.143 3.143 0 0 0-2.39-3.84c-1.661-.354-3.294.729-3.711 2.377ZM161.928 26.451l-.175.733-.121.507a1.18 1.18 0 0 0 .874 1.422l2.442.583c.635.15 1.271-.24 1.421-.875l.121-.507.188-.792a2.44 2.44 0 0 0-1.896-2.956c-1.292-.258-2.549.6-2.854 1.883v.002ZM215.849 50.731c-1.242 1.356-3.351 3.105-5.73 2.767-.078-2.533 2.051-5.401 3.648-6.748.238-.201.489-.403.75-.602l1.422 1.762a2.163 2.163 0 0 1-.092 2.821h.002ZM216.877 93.49a2.781 2.781 0 0 1 2.767 1.012l.551.711c-2.087.782-4.441 1.915-6.456 1.605-.066-.01-.131-.022-.197-.033a4.629 4.629 0 0 1-2.148-1.029c1.085-1.17 3.714-1.894 5.483-2.264v-.002Z"
          fill="#FF8702"
        />
        <path
          d="M286.295 145.89s-23.247-99.991-23.252-99.99l-4.257-20.03a3.29 3.29 0 1 0-6.439 1.37l4.41 20.483-.471.137-5.529-25.498a4.127 4.127 0 0 0-4.939-3.115 4.13 4.13 0 0 0-3.112 4.943l5.338 26.062-.579.168-6.123-30.087a5.116 5.116 0 0 0-5.867-3.433l-.331.066c-3.149.63-4.658 3.97-3.446 6.946l5.523 24.38c-8.026-14.812-18.979-6.52-22.703-2.148l1.422 1.763a2.162 2.162 0 0 1-.091 2.82c-1.243 1.357-3.351 3.105-5.731 2.768.019.609.163 1.197.474 1.742 1.045 1.83 5.366 1.274 6.594.147 2.949-2.707 6.039-5.236 10.248-3.812 3.686 1.247 6.135 5.237 7.424 8.715.953 2.572 1.267 5.423.602 8.084-1.269 5.075-5.665 8.657-9.671 11.639-4.225 3.144-8.855 5.694-13.54 8.074-3.166 1.606-3.174 5.68-.854 7.669 1.086-1.171 3.714-1.894 5.484-2.264a2.783 2.783 0 0 1 2.767 1.011l.551.711c.453-.17.893-.323 1.317-.442 2.492-.7 5.046-1.234 7.631-1.407 5.01-.335 10.177.91 13.174 5.253 0 0 12.746 13.041 16.358 46.637"
          fill="#7F4036"
        />
        <path
          d="m239.81 20.947.283 1.121.195.775a1.81 1.81 0 0 1-1.31 2.2l-3.732.945a1.81 1.81 0 0 1-2.199-1.313l-.195-.775-.306-1.21a3.742 3.742 0 0 1 2.846-4.572c1.979-.422 3.922.866 4.418 2.83ZM249.715 22.952l.239.942.164.65a1.52 1.52 0 0 1-1.101 1.848l-3.135.792a1.518 1.518 0 0 1-1.845-1.102l-.164-.65-.258-1.017a3.143 3.143 0 0 1 2.39-3.84c1.661-.354 3.294.729 3.71 2.377ZM257.9 26.451l.174.733.121.507a1.18 1.18 0 0 1-.874 1.422l-2.442.583a1.18 1.18 0 0 1-1.421-.875l-.121-.507-.188-.792a2.44 2.44 0 0 1 1.896-2.956c1.292-.258 2.549.6 2.855 1.883v.002Z"
          fill="#FF8702"
        />
      </g>
      <path
        d="M248.45 70.639c.788 0 1.542.142 2.247.39 1.057-4.877 5.392-8.533 10.583-8.533 1.465 0 2.862.292 4.137.82 1.331-7.984 8.261-14.07 16.614-14.07 9.306 0 16.85 7.55 16.85 16.863 0 2.065-.372 4.042-1.051 5.87a6.724 6.724 0 0 1 4.027-1.34 6.768 6.768 0 0 1 6.764 6.77 6.766 6.766 0 0 1-6.13 6.736v.033h-53.922v-.005c-.039 0-.077.005-.117.005a6.767 6.767 0 0 1-6.764-6.77 6.767 6.767 0 0 1 6.764-6.77h-.002ZM95.641 110.53a6.06 6.06 0 0 1 2.015.35c.95-4.378 4.839-7.657 9.495-7.657 1.315 0 2.568.263 3.712.737 1.194-7.163 7.41-12.625 14.907-12.625 8.348 0 15.116 6.773 15.116 15.129 0 1.852-.334 3.625-.942 5.267a6.023 6.023 0 0 1 3.614-1.202 6.07 6.07 0 0 1 6.067 6.072 6.07 6.07 0 0 1-5.499 6.045v.03H95.749v-.006c-.037 0-.071.006-.106.006a6.07 6.07 0 0 1-6.067-6.073 6.07 6.07 0 0 1 6.067-6.073h-.002Z"
        fill="#fff"
      />
    </svg>
  );
}
