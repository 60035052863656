import * as React from "react";
import { SVGProps } from "react";
export function HeartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 16.25 3 9.3c-4.2-4.1 1.85-12.05 7.5-5.65C16.15-2.75 22.2 5.2 18 9.3l-7.5 6.95Z"
        fill="fillColor"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
