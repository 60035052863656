import Image from "next/image";
import { CldImage } from "next-cloudinary";
import { CSSProperties } from "react";

export interface IImageWrapperProps {
  width?: number;
  height?: number;

  src?: string;
  alt?: string;
  placeholderUrl?: string;
  className?: string;
  objectFit?: string;
  layout?: string;

  isCLDImage?: boolean;
  fill?: boolean;
  priority?: boolean;
  unoptimized?: boolean;

  style?: CSSProperties;
  loading?: "eager" | "lazy";
}

export const ImageWrapper = ({
  isCLDImage = false,
  placeholderUrl = "/bg-light-gray-gradiant.jpeg",
  src,
  width,
  height,
  alt = "",
  fill,
  priority,
  className,
  style,
  loading,
  objectFit,
  unoptimized,
  layout,
}: IImageWrapperProps): JSX.Element => {
  if (!src) {
    return (
      <Image
        src={placeholderUrl}
        fill={fill}
        width={width}
        height={height}
        alt={alt}
        className={className}
        priority={priority}
        style={style}
        loading={loading}
        objectFit={objectFit}
        layout={layout}
      />
    );
  }
  return (
    <>
      {isCLDImage ? (
        <CldImage
          src={src}
          fill={fill}
          width={width}
          height={height}
          alt={alt}
          className={className}
          priority={priority}
          style={style}
          loading={loading}
          objectFit={objectFit}
          unoptimized={unoptimized}
        />
      ) : (
        <Image
          fill={fill}
          src={src}
          width={width}
          height={height}
          alt={alt}
          className={className}
          priority={priority}
          style={style}
          loading={loading}
          objectFit={objectFit}
          layout={layout}
          unoptimized={unoptimized}
        />
      )}
    </>
  );
};
