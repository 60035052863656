import * as React from "react";
import { SVGProps } from "react";
export function BuildingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5.5h9a1 1 0 0 1 1 1v12h-11v-12a1 1 0 0 1 1-1ZM4 3h1.5M8.5 3H10M4 5.5h1.5M8.5 5.5H10M4 8h1.5M8.5 8H10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 10.5h-2.5v3h2.5v-3Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
