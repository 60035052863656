import * as React from "react";
import { SVGProps } from "react";
export function VerticalDotsMenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 5 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 7.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM4 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM4 2.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
