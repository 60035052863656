import { VariantProps, cva } from "class-variance-authority";
import { AnchorHTMLAttributes } from "react";

import { Link } from "../link/Link";

export interface ILinkButtonProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonClasses> {
  children: string | JSX.Element;
  href: string;
  className?: string;
  isAbsolute?: boolean;
  style?: { [key: string]: string | undefined };
}

export function LinkButton({
  children,
  className,
  isDisabled,
  isLoading,
  intent,
  size,
  fontSize,
  isAbsolute = false,
  style,
  ...props
}: Readonly<ILinkButtonProps>): JSX.Element {
  return (
    <Link
      style={style}
      decoration={false}
      data-testid="button-test-id"
      className={buttonClasses({
        intent,
        size,
        fontSize,
        isDisabled,
        isLoading,
        className,
      })}
      hasHoverEffect={false}
      isAbsolute={isAbsolute}
      {...props}
    >
      {children}
    </Link>
  );
}

const buttonClasses = cva(
  "py-2.5 px-8 rounded-full flex justify-center items-center whitespace-nowrap hover:shadow-lg",
  {
    variants: {
      intent: {
        primary: "bg-primary text-black hover:text-black",
        secondary: "bg-secondary text-white hover:text-white",
      },
      size: {
        xs: "w-20",
        sm: "w-28",
        md: "w-60",
        lg: "w-72",
        full: "w-fill",
      },
      fontSize: {
        xs: "text-xs",
        sm: "text-sm",
        base: "text-base",
        md: "text-md",
        lg: "text-lg ",
        xl: "text-xl",
      },
      isDisabled: {
        true: "bg-disabled text-gray-900 cursor-not-allowed !important",
        false: "",
      },
      isLoading: {
        true: "cursor-not-allowed !important",
        false: "",
      },
    },

    defaultVariants: {
      intent: "primary",
      size: "md",
      fontSize: "base",
      isDisabled: false,
      isLoading: false,
    },
  }
);
