import {
  getCookie,
  setCookie,
  deleteCookie,
  hasCookie,
  CookieValueTypes,
} from "cookies-next";

import { CookieKeys } from "../enums/CookieKeys.enum";

interface ICookieOptions {
  key: CookieKeys;
  value?: CookieValueTypes;
  maxAge?: number;
}
const oneMonthInSeconds = 30 * 24 * 60 * 60;
export const setCookieUtil = ({
  key,
  value,
  maxAge = oneMonthInSeconds,
}: ICookieOptions): void => {
  const valueToStore =
    typeof value === "object" && value !== null ? JSON.stringify(value) : value;
  setCookie(key, valueToStore, { maxAge });
};

export const getCookieUtil = (key: CookieKeys): string | undefined =>
  getCookie(key) as string | undefined; // Adjust the type casting as needed

export const deleteCookieUtil = (key: CookieKeys): void => {
  deleteCookie(key);
};

export const hasCookieUtil = (key: CookieKeys): boolean => hasCookie(key);
