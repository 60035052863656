"use client";

import { VariantProps, cva } from "class-variance-authority";
import { ReactElement } from "react";
import { Button as AriaButton, type ButtonProps } from "react-aria-components";

import { Spinner } from "../spinner/Spinner";

export interface IButtonProps
  extends VariantProps<typeof buttonClasses>,
    ButtonProps {
  children?: React.ReactNode;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  type?: "button" | "submit" | "reset";
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export function Button({
  children,
  startIcon,
  endIcon,
  type = "submit",
  className,
  isDisabled,
  isLoading,
  intent,
  size,
  onClick,
  ...props
}: Readonly<IButtonProps>): JSX.Element {
  return (
    <AriaButton
      data-testid="button-test-id"
      className={buttonClasses({
        intent,
        size,
        isDisabled,
        isLoading,
        className,
      })}
      type={type}
      isDisabled={isDisabled ?? isLoading}
      onPress={() => onClick?.()}
      {...props}
    >
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {children}
      {endIcon && <span className="ml-2">{endIcon}</span>}
      {isLoading && (
        <div className="ml-4">
          <Spinner intent={intent} />
        </div>
      )}
    </AriaButton>
  );
}

const buttonClasses = cva(
  "py-2 px-8 rounded-full h-[40px] flex justify-center items-center whitespace-nowrap hover:shadow-lg text-sm",
  {
    variants: {
      intent: {
        primary: "bg-primary",
        secondary: "bg-secondary text-white",
        success: "bg-success text-white",
        danger: "bg-danger text-white",
      },
      size: {
        xxs: "w-10 h-10 rounded-full",
        xs: "w-28",
        sm: "w-44",
        md: "w-60",
        lg: "w-72",
        xl: "w-82",
        xxl: "w-[600px]",
        full: "w-full",
        none: "",
      },
      isDisabled: {
        true: "!bg-background-light !text-black bg-disabled text-gray-900 cursor-not-allowed",
        false: "",
      },
      isLoading: {
        true: "cursor-not-allowed",
        false: "",
      },
    },

    defaultVariants: {
      intent: "primary",
      size: "md",
      isDisabled: false,
      isLoading: false,
    },
  }
);
