import * as React from "react";
import { SVGProps } from "react";
export function LinkedInNoColorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={41} height={41} rx={2} fill="silver" fillOpacity={0.2} />
      <path
        d="M15 31.292h-4.5V15.875H15v15.417Zm16.5 0H27v-8.236c0-2.146-.744-3.214-2.218-3.214-1.169 0-1.91.598-2.282 1.796v9.654H18s.06-13.875 0-15.417h3.552l.274 3.083h.093c.923-1.541 2.398-2.587 4.42-2.587 1.537 0 2.78.44 3.73 1.544.956 1.105 1.431 2.588 1.431 4.67v8.707ZM12.75 14.333a2.319 2.319 0 0 0 2.325-2.312 2.319 2.319 0 0 0-2.325-2.313 2.319 2.319 0 0 0-2.325 2.313 2.319 2.319 0 0 0 2.325 2.312Z"
        fill="#7B83AC"
        fillOpacity={0.3}
      />
    </svg>
  );
}
