import * as React from "react";
import { SVGProps } from "react";
export function WelcomeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={334}
      height={150}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M109.45 121.456c29.846 0 54.04-24.215 54.04-54.086 0-29.872-24.194-54.087-54.04-54.087S55.41 37.498 55.41 67.37c0 29.87 24.194 54.086 54.04 54.086Z"
        fill="#FF8702"
      />
      <path
        d="M313.883 40.386H155.005a5.35 5.35 0 0 0-4.785 2.96l-47.465 95.01H5.817A5.82 5.82 0 0 0 0 144.178 5.82 5.82 0 0 0 5.817 150h255.972a9.842 9.842 0 0 0 8.863-5.566l2.681-5.55 45.336-90.75c1.778-3.559-.809-7.748-4.786-7.748Z"
        fill="#fff"
      />
      <path
        d="M310.04 48.12H156.474l-44.645 90.091h153.566l44.645-90.092Z"
        fill="#0E93DE"
      />
      <path
        d="M77.857 138.163h35.678v3.753a3.428 3.428 0 0 1-3.425 3.428H81.283a3.428 3.428 0 0 1-3.426-3.428v-3.753Z"
        fill="#C6C4C4"
      />
      <path
        d="m124.537 51.098-17.103 36.335-3.778-12.811L84.56 91.949l-4.618-4.936L99.46 69.476 86.554 64.96l37.983-13.862Z"
        fill="#fff"
      />
      <mask
        id="welcome_svg__a"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={111}
        y={0}
        width={223}
        height={139}
      >
        <path
          d="M333.966 0H156.474l-44.645 138.211h153.566L333.966 0Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#welcome_svg__a)">
        <path
          d="M203.961 50.73c1.243 1.357 3.351 3.106 5.731 2.768.078-2.534-2.051-5.401-3.648-6.749-.239-.2-.489-.403-.75-.602l-1.422 1.763a2.162 2.162 0 0 0 .091 2.82h-.002ZM202.931 93.49a2.78 2.78 0 0 0-2.766 1.012l-.552.71c2.088.783 4.442 1.915 6.457 1.606.065-.01.131-.023.197-.033a4.631 4.631 0 0 0 2.148-1.03c-1.086-1.17-3.714-1.893-5.484-2.263v-.002Z"
          fill="#FF8702"
        />
        <path
          d="M126.124 169.71S156.762 45.898 156.766 45.9l4.256-20.03a3.291 3.291 0 1 1 6.439 1.37l-4.41 20.483.472.137 5.528-25.498a4.127 4.127 0 1 1 8.051 1.828l-5.338 26.062.579.167 6.123-30.086a5.116 5.116 0 0 1 5.867-3.433l.332.066c3.148.63 4.657 3.969 3.446 6.946l-5.524 24.38c8.026-14.812 18.979-6.52 22.703-2.148l-1.422 1.762a2.163 2.163 0 0 0 .091 2.821c1.243 1.356 3.351 3.105 5.731 2.768a3.694 3.694 0 0 1-.474 1.742c-1.045 1.83-5.366 1.274-6.594.146-2.948-2.706-6.038-5.235-10.248-3.812-3.686 1.248-6.135 5.238-7.424 8.716-.952 2.572-1.267 5.422-.602 8.084 1.269 5.075 5.665 8.657 9.671 11.639 4.225 3.144 8.855 5.693 13.54 8.073 3.166 1.607 3.175 5.68.854 7.67-1.086-1.171-3.714-1.894-5.484-2.265a2.78 2.78 0 0 0-2.766 1.012l-.552.711c-.452-.17-.893-.323-1.316-.443-2.492-.698-5.047-1.233-7.632-1.406-5.01-.335-10.177.91-13.174 5.253 0 0-13.216 37.269-20.628 70.457"
          fill="#7F4036"
        />
        <path
          d="m179.998 20.947-.283 1.12-.195.776a1.81 1.81 0 0 0 1.31 2.2l3.732.944a1.81 1.81 0 0 0 2.199-1.313l.195-.775.306-1.21a3.742 3.742 0 0 0-2.847-4.572c-1.978-.422-3.921.867-4.417 2.83ZM170.093 22.952l-.239.942-.164.65a1.521 1.521 0 0 0 1.101 1.848l3.135.792a1.52 1.52 0 0 0 1.846-1.102l.164-.65.257-1.017a3.142 3.142 0 0 0-2.39-3.84c-1.66-.354-3.294.728-3.71 2.377ZM161.91 26.45l-.175.734-.121.507a1.18 1.18 0 0 0 .875 1.421l2.442.583c.634.15 1.27-.24 1.42-.875l.121-.507.188-.792a2.44 2.44 0 0 0-1.895-2.956c-1.293-.257-2.549.6-2.855 1.884v.002ZM215.831 50.73c-1.242 1.357-3.351 3.106-5.73 2.768-.078-2.534 2.051-5.401 3.648-6.749.238-.2.489-.403.75-.602l1.422 1.763a2.162 2.162 0 0 1-.092 2.82h.002ZM216.859 93.49a2.781 2.781 0 0 1 2.767 1.012l.551.71c-2.087.783-4.441 1.915-6.456 1.606-.066-.01-.131-.023-.197-.033a4.627 4.627 0 0 1-2.148-1.03c1.085-1.17 3.714-1.893 5.483-2.263v-.002Z"
          fill="#FF8702"
        />
        <path
          d="M286.277 145.89S263.03 45.898 263.025 45.9l-4.256-20.03a3.292 3.292 0 1 0-6.439 1.37l4.41 20.483-.472.137-5.528-25.498a4.127 4.127 0 0 0-4.939-3.115 4.132 4.132 0 0 0-3.113 4.943l5.338 26.062-.578.167-6.123-30.086a5.117 5.117 0 0 0-5.867-3.433l-.332.066c-3.149.63-4.657 3.969-3.446 6.946l5.523 24.38c-8.025-14.812-18.978-6.52-22.702-2.148l1.422 1.762a2.163 2.163 0 0 1-.092 2.821c-1.242 1.356-3.351 3.105-5.73 2.768.019.609.162 1.197.473 1.742 1.046 1.83 5.366 1.274 6.595.146 2.948-2.706 6.038-5.235 10.248-3.812 3.686 1.248 6.135 5.238 7.424 8.716.952 2.572 1.266 5.422.601 8.084-1.268 5.075-5.665 8.657-9.67 11.639-4.226 3.144-8.855 5.693-13.54 8.073-3.166 1.607-3.175 5.68-.854 7.67 1.085-1.171 3.714-1.894 5.483-2.265a2.781 2.781 0 0 1 2.767 1.012l.551.711c.453-.17.894-.323 1.317-.443 2.492-.698 5.046-1.233 7.632-1.406 5.009-.335 10.177.91 13.173 5.253 0 0 12.747 13.041 16.359 46.637"
          fill="#7F4036"
        />
        <path
          d="m239.792 20.947.283 1.12.195.776a1.811 1.811 0 0 1-1.309 2.2l-3.733.944a1.809 1.809 0 0 1-2.198-1.313l-.196-.775-.306-1.21a3.742 3.742 0 0 1 2.847-4.572c1.978-.422 3.921.867 4.417 2.83ZM249.697 22.952l.239.942.164.65a1.52 1.52 0 0 1-1.101 1.848l-3.135.792a1.518 1.518 0 0 1-1.845-1.102l-.165-.65-.257-1.017a3.143 3.143 0 0 1 2.39-3.84c1.661-.354 3.294.728 3.71 2.377ZM257.883 26.45l.174.734.121.507a1.18 1.18 0 0 1-.874 1.421l-2.442.583a1.18 1.18 0 0 1-1.421-.875l-.121-.507-.188-.792a2.44 2.44 0 0 1 1.896-2.956c1.292-.257 2.549.6 2.855 1.884v.002Z"
          fill="#FF8702"
        />
      </g>
      <path
        d="M248.434 70.639c.788 0 1.542.141 2.247.39 1.057-4.877 5.391-8.533 10.583-8.533 1.465 0 2.862.292 4.137.82 1.331-7.984 8.26-14.07 16.614-14.07 9.306 0 16.85 7.549 16.85 16.863 0 2.065-.372 4.042-1.051 5.87a6.723 6.723 0 0 1 4.026-1.34 6.767 6.767 0 0 1 6.764 6.77 6.765 6.765 0 0 1-6.129 6.736v.033h-53.922v-.005c-.039 0-.077.005-.117.005a6.767 6.767 0 0 1-6.764-6.77 6.767 6.767 0 0 1 6.764-6.77h-.002ZM95.624 110.53c.707 0 1.385.128 2.015.349.95-4.377 4.839-7.656 9.495-7.656 1.315 0 2.568.262 3.712.736 1.194-7.162 7.41-12.624 14.907-12.624 8.348 0 15.116 6.773 15.116 15.129 0 1.852-.334 3.625-.942 5.266a6.029 6.029 0 0 1 3.613-1.202 6.07 6.07 0 0 1 6.068 6.073 6.07 6.07 0 0 1-5.499 6.045v.029H95.731v-.005c-.036 0-.07.005-.105.005a6.07 6.07 0 0 1-6.067-6.072 6.07 6.07 0 0 1 6.067-6.073h-.002Z"
        fill="#fff"
      />
    </svg>
  );
}
