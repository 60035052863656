import { VariantProps, cva } from "class-variance-authority";
import React from "react";

import { ImageWrapper } from "../image-wrapper/ImageWrapper";

export interface IAvatarProps
  extends VariantProps<typeof avatarClasses>,
    VariantProps<typeof userStatusClass> {
  src?: string;
  alt: string;
  className?: string;
}

export function Avatar({
  src,
  alt,
  size,
  status,
  className,
}: IAvatarProps): JSX.Element {
  if (src) {
    return (
      <span className="relative inline-block">
        <div className={avatarClasses({ size, className })}>
          <ImageWrapper
            src={src}
            alt={alt}
            fill
            objectFit="cover"
            isCLDImage
            style={{ borderRadius: "100%" }}
          />
        </div>
        {status && <span className={userStatusClass({ status, size })} />}
      </span>
    );
  }
  return (
    <span className="relative inline-block">
      <div className={avatarClasses({ size, className })}>
        <ImageWrapper
          src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1709897247/web-app-images/my-g-work-small_nbamrj.svg"
          alt={alt}
          fill
        />
      </div>
      {status && <span className={userStatusClass({ status, size })} />}
    </span>
  );
}

const userStatusClass = cva(
  "absolute top-0 right-0 block rounded-full  ring-2 ring-white",
  {
    variants: {
      status: {
        online: "bg-green-400",
        offline: "bg-gray-400",
      },
      size: {
        xxs: "w-1 md:w-1.5 h-1 md:h-1.5",
        xs: "w-1.5 md:w-2 h-1.5 md:h-2",
        sm: "h-2.5 w-2.5",
        md: "h-2.5 w-2.5",
        lg: "h-3 w-3",
        xl: "h-3.5 w-3.5",
      },
    },
  }
);

const avatarClasses = cva("relative w-10 h-10 !rounded-full !overflow-hidden", {
  variants: {
    size: {
      xxs: "w-6 md:w-8 h-6 md:h-8",
      xs: "w-8 md:w-12 h-8 md:h-12",
      sm: "w-16 h-16",
      md: "w-16 h-16 md:w-20 md:h-20",
      lg: "w-36 h-36",
      xl: "w-52 h-52",
    },
  },
  defaultVariants: {
    size: "md",
  },
});
