import * as React from "react";
import { SVGProps } from "react";
export function DownloadAltIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 16.5a.872.872 0 0 0-.863.863V20.7c0 .338-.262.6-.6.6H2.963a.592.592 0 0 1-.6-.6v-3.337A.872.872 0 0 0 1.5 16.5a.872.872 0 0 0-.862.863V20.7a2.273 2.273 0 0 0 2.287 2.288h18.113a2.273 2.273 0 0 0 2.287-2.288v-3.337c.038-.488-.375-.863-.825-.863Z"
        fill="currentColor"
      />
      <path
        d="M11.4 17.438c.15.15.375.225.6.225a.92.92 0 0 0 .6-.226l5.438-5.287a.838.838 0 0 0 0-1.2.838.838 0 0 0-1.2 0l-3.976 3.9V1.875A.872.872 0 0 0 12 1.013a.872.872 0 0 0-.863.862V14.85l-3.974-3.9c-.338-.337-.863-.3-1.2 0-.338.338-.3.863 0 1.2l5.437 5.287Z"
        fill="currentColor"
      />
    </svg>
  );
}
