"use client";

import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { Button as AriaButton, type ButtonProps } from "react-aria-components";

export interface ITextButtonProps
  extends ButtonProps,
    VariantProps<typeof textButtonClasses> {}

export function TextButton({
  children,
  hover,
  underLine,
  ...props
}: ITextButtonProps): JSX.Element {
  return (
    <AriaButton className={textButtonClasses({ hover, underLine })} {...props}>
      {children}
    </AriaButton>
  );
}

const textButtonClasses = cva("outline-none", {
  variants: {
    hover: {
      true: "hover:text-brand-primary",
      false: "",
    },
    underLine: {
      true: "underline underline-offset-2",
      false: "",
    },
  },
  defaultVariants: {
    hover: false,
    underLine: false,
  },
});
