import { CircleIcon } from "icons";
import { Radio as AriaRadio, type RadioProps } from "react-aria-components";

import { Text } from "../Text/Text";

export interface IRadioProps extends RadioProps {
  label: string;
  value: string;
}

export function Radio({ label, value, ...props }: IRadioProps): JSX.Element {
  return (
    <AriaRadio
      value={value}
      {...props}
      className="
      cursor-pointer flex flex-row justify-start items-center gap-4  w-80 p-3 rounded-md border border-background-dark"
    >
      {({ isSelected }) => (
        <>
          {isSelected ? (
            <div className="w-6 h-6 bg-brand-primary rounded-full" />
          ) : (
            <CircleIcon className="w-6 h-6 text-brand-dark-gray" />
          )}
          <Text>{label}</Text>
        </>
      )}
    </AriaRadio>
  );
}
