"use client";

import { CheckBoxIcon, BoxIcon } from "icons";
import type { CheckboxProps } from "react-aria-components";
import { Checkbox as AriaCheckbox } from "react-aria-components";

import { Text } from "../Text/Text";

export interface ICheckboxProps extends CheckboxProps {
  label?: string | JSX.Element;
  description?: string;
  isBoldLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export function Checkbox({
  label,
  description,
  isBoldLabel,
  isRequired,
  errorMessage,
  ...props
}: ICheckboxProps): JSX.Element {
  return (
    <div>
      <AriaCheckbox
        {...props}
        className={`flex flex-row gap-x-2 ${
          description ? "items-start" : "items-center"
        } cursor-pointer group`}
      >
        {({ isSelected }) => (
          <>
            <div className="border-2 border-transparent group-focus:border-primary rounded-lg">
              {isSelected ? <CheckBoxIcon /> : <BoxIcon />}
            </div>
            <Text variant="small" align="left">
              {isBoldLabel ? <strong>{label}</strong> : label}
              {description && (
                <>
                  <br />
                  {description}
                </>
              )}
              {isRequired && (
                <Text variant="small" intent="secondary" align="left">
                  (required)
                </Text>
              )}
            </Text>
          </>
        )}
      </AriaCheckbox>
      <Text variant="small" intent="danger">
        {errorMessage}
      </Text>
    </div>
  );
}
