import * as React from "react";
import { SVGProps } from "react";
export function FlashlightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.735 20.519 1.48 18.265a1.643 1.643 0 0 1 0-2.324l7.23-7.23V4.134l2.653-2.653a1.643 1.643 0 0 1 2.324 0l6.83 6.831a1.643 1.643 0 0 1 0 2.324l-2.652 2.653H13.29l-7.23 7.23a1.643 1.643 0 0 1-2.324 0ZM9.823 3.022l9.154 9.155"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
