import * as React from "react";
import { SVGProps } from "react";
export function UserCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11.428a3.571 3.571 0 1 0 0-7.143 3.571 3.571 0 0 0 0 7.143ZM3.9 17a7.141 7.141 0 0 1 12.2 0"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.285A9.286 9.286 0 1 0 .714 10 9.286 9.286 0 0 0 10 19.286Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
