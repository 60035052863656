import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

import { customClient } from "../utils/api";

/**
 * Interface for the variables required to upload a file.
 */
interface IUploadFileVariables {
  file: File;
}

/**
 * Extends IUploadFileVariables with an optional progress callback function.
 */
interface IUploadFileVariablesWithProgress {
  data: IUploadFileVariables;
  uploadProgress?: (progress: number) => void;
}

/**
 * Function to upload assets using the custom Axios client.
 *
 * @param createAssetDto - The data object containing file and metadata for the upload.
 * @param uploadProgress - Optional callback to track the upload progress.
 * @returns A promise resolving to the response from the asset upload API.
 */
export const uploadFunction = async (
  createAssetDto: IUploadFileVariables,
  uploadProgress?: (progress: number) => void
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", createAssetDto.file);
  formData.append("fileType", createAssetDto.file.type);
  formData.append("original_name", createAssetDto.file.name);

  return customClient<string>({
    url: "/asset/upload",
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        uploadProgress?.(percentCompleted);
      }
    },
  });
};

/**
 * Generates mutation options for the React Query useMutation hook.
 *
 * @param options - Optional configuration for the mutation.
 * @returns Configuration object for useMutation.
 */
export const getAssetCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFunction>>,
    TError,
    IUploadFileVariablesWithProgress,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadFunction>>,
  TError,
  IUploadFileVariablesWithProgress,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadFunction>>,
    IUploadFileVariablesWithProgress
  > = ({ data, uploadProgress }) => uploadFunction(data, uploadProgress);
  return { mutationFn, ...mutationOptions };
};

/**
 * Custom hook for uploading assets using React Query.
 *
 * @param options - Optional configuration for the mutation.
 * @returns The result object from useMutation, including the mutation function and status.
 */
export const useUpload = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFunction>>,
    TError,
    IUploadFileVariablesWithProgress,
    TContext
  >;
}): UseMutationResult<
  string,
  TError,
  IUploadFileVariablesWithProgress,
  TContext
> => {
  const mutationOptions = getAssetCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
