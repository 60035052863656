import * as React from "react";
import { SVGProps } from "react";
export function EventCalendarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 56 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.98 58.457H4.02c-2.185 0-3.972-1.771-3.972-3.94v-41.85c0-2.169 1.787-3.94 3.973-3.94h5.185c.638 0 1.148.51 1.148 1.149 0 .638-.51 1.149-1.148 1.149H4.02c-.926 0-1.676.733-1.676 1.643v11.136h51.326V12.668c0-.91-.75-1.643-1.676-1.643h-4.85c-.638 0-1.148-.511-1.148-1.15 0-.637.51-1.148 1.148-1.148h4.85c2.186 0 3.973 1.771 3.973 3.94v41.85c-.016 2.169-1.803 3.94-3.989 3.94ZM2.345 26.101v28.415c0 .91.75 1.644 1.675 1.644h47.96c.925 0 1.675-.734 1.675-1.644V26.101H2.345Zm30.473-15.076h-9.891c-.639 0-1.15-.511-1.15-1.15 0-.637.511-1.148 1.15-1.148h9.891c.638 0 1.149.51 1.149 1.149 0 .638-.51 1.149-1.149 1.149Z"
        fill="#000"
      />
      <path
        d="M37.334 49.778H30.17V47.48h7.163v2.298Zm-11.424 0h-7.163V47.48h7.163v2.298Zm-11.407 0h-7.18V47.48h7.18v2.298Zm34.27-8.344h-7.18v-2.298h7.18v2.298Zm-11.44 0H30.17v-2.298h7.163v2.298Zm-11.423 0h-7.163v-2.298h7.163v2.298Zm-11.407 0h-7.18v-2.298h7.18v2.298Zm34.27-8.344h-7.18v-2.298h7.18v2.298Zm-11.44 0H30.17v-2.298h7.163v2.298Zm-11.423 0h-7.163v-2.298h7.163v2.298Zm-11.407 0h-7.18v-2.298h7.18v2.298Zm1.531-17.742c-.638 0-1.148-.51-1.148-1.149V5.378c0-.638.51-1.149 1.148-1.149.639 0 1.15.51 1.15 1.149v8.822c0 .623-.512 1.15-1.15 1.15Zm23.996 0c-.638 0-1.149-.51-1.149-1.149V5.378c0-.638.51-1.149 1.149-1.149.638 0 1.148.51 1.148 1.149v8.822c0 .623-.51 1.15-1.148 1.15Z"
        fill="#000"
      />
    </svg>
  );
}
