import * as React from "react";
import { SVGProps } from "react";
export function LocationPinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#location-pin_svg__a)"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.265 9.123C20.265 14.633 12 21.06 12 21.06S3.734 14.633 3.734 9.123C3.734 4.62 7.498.857 12 .857c4.502 0 8.265 3.764 8.265 8.266Z" />
        <path d="M12 11.878a2.755 2.755 0 1 0 0-5.51 2.755 2.755 0 0 0 0 5.51ZM4.653 18.323c-2.255.673-3.674 1.65-3.674 2.738 0 2.03 4.934 3.674 11.02 3.674 6.087 0 11.021-1.645 11.021-3.674 0-1.088-1.419-2.065-3.673-2.738" />
      </g>
      <defs>
        <clipPath id="location-pin_svg__a">
          <path fill="#fff" d="M0 0h24v25.714H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
