"use client";

import { VariantProps, cva } from "class-variance-authority";
import { FormEvent, ReactNode } from "react";
import { Form as AriaForm } from "react-aria-components";

export interface IFormProps extends VariantProps<typeof formClass> {
  children: ReactNode;
  className?: string;
  onSubmit: (data: FormEvent<HTMLFormElement>) => void;
}

export function Form({
  children,
  className,
  onSubmit,
}: IFormProps): JSX.Element {
  return (
    <AriaForm className={formClass({ className })} onSubmit={onSubmit}>
      {children}
    </AriaForm>
  );
}

const formClass = cva("");
