import * as React from "react";
import { SVGProps } from "react";
export function XIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#x_svg__a)">
        <path
          d="M14.993 29.967c8.264 0 14.964-6.7 14.964-14.964S23.257.04 14.993.04.029 6.739.029 15.003s6.7 14.964 14.964 14.964Z"
          fill="#000"
          stroke="#fff"
          strokeMiterlimit={10}
        />
        <path
          d="m5.776 6.333 7.152 9.564-7.198 7.775h1.62l6.302-6.807 5.091 6.807h5.513l-7.555-10.1 6.7-7.238h-1.62l-5.804 6.269-4.689-6.27H5.776Zm2.382 1.194h2.532l11.183 14.952h-2.532L8.158 7.527Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="x_svg__a">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
