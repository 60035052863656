import * as React from "react";
import { SVGProps } from "react";

export function WordIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group">
                <path
                    id="Vector"
                    d="M3.97578 11.175C4.42578 11.175 4.83828 10.8 4.83828 10.3125V2.99999C4.83828 2.62499 5.13828 2.32499 5.51328 2.32499H13.9883V7.12499C13.9883 7.57499 14.3633 7.98749 14.8508 7.98749H19.6133V10.125C19.6133 10.575 19.9883 10.9875 20.4758 10.9875C20.9633 10.9875 21.3383 10.6125 21.3383 10.125V7.34999C21.3383 6.93749 21.1508 6.52499 20.8508 6.22499L15.6008 1.12499C15.3008 0.824988 14.8883 0.674988 14.4758 0.674988H5.47578C4.20078 0.674988 3.11328 1.72499 3.11328 3.03749V10.35C3.15078 10.7625 3.52578 11.175 3.97578 11.175ZM15.6383 3.52499L18.4508 6.29999H15.6383V3.52499Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_2"
                    d="M20.4012 19.425C19.9512 19.425 19.5387 19.8 19.5387 20.2875V21.0375C19.5387 21.4125 19.2387 21.7125 18.8637 21.7125H5.47617C5.10117 21.7125 4.80117 21.4125 4.80117 21.0375V20.5125C4.80117 20.0625 4.42617 19.65 3.93867 19.65C3.45117 19.65 3.07617 20.025 3.07617 20.5125V21C3.07617 22.275 4.12617 23.3625 5.43867 23.3625H18.8262C20.1012 23.3625 21.1887 22.3125 21.1887 21V20.25C21.2262 19.8 20.8512 19.425 20.4012 19.425Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_3"
                    d="M21.2633 11.3625H19.5383C19.0883 11.3625 18.6758 11.7375 18.6758 12.225V17.8875C18.6758 18.3375 19.0508 18.75 19.5383 18.75H21.2633C22.5758 18.75 23.6633 17.6625 23.6633 16.3125V13.8375C23.6633 12.45 22.5758 11.3625 21.2633 11.3625ZM21.9758 16.275C21.9758 16.6875 21.6383 17.025 21.2633 17.025H20.4008V13.05H21.2633C21.6383 13.05 21.9758 13.3875 21.9758 13.8V16.275Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_4"
                    d="M17.6629 14.775V12.3C17.6629 11.85 17.2879 11.4375 16.8004 11.4375H14.1379C13.6879 11.4375 13.2754 11.8125 13.2754 12.3V17.8875C13.2754 18.3375 13.6504 18.75 14.1379 18.75C14.6254 18.75 15.0004 18.375 15.0004 17.8875V15.9L16.5004 18.3375C16.6504 18.6 16.9504 18.75 17.2129 18.75C17.3629 18.75 17.5129 18.7125 17.6629 18.6375C18.0754 18.375 18.1879 17.8875 17.9254 17.475L16.8004 15.675C17.2879 15.6375 17.6629 15.2625 17.6629 14.775ZM15.0004 13.125H15.9754V13.9125H15.0004V13.125Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_5"
                    d="M7.6875 13.8V16.3875C7.6875 17.7 8.7375 18.75 10.05 18.75C11.3625 18.75 12.4125 17.7 12.4125 16.3875V13.8C12.4125 12.4875 11.3625 11.4375 10.05 11.4375C8.7375 11.4375 7.6875 12.525 7.6875 13.8ZM10.725 13.8V16.3875C10.725 16.7625 10.425 17.0625 10.05 17.0625C9.675 17.0625 9.375 16.7625 9.375 16.3875V13.8C9.375 13.425 9.675 13.125 10.05 13.125C10.425 13.125 10.725 13.425 10.725 13.8Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_6"
                    d="M3.93824 18.075L4.05074 17.55L4.16324 18.075C4.23824 18.45 4.57574 18.7125 4.95074 18.75C5.32574 18.75 5.70074 18.525 5.77574 18.15L7.27574 12.8625C7.38824 12.4125 7.12574 11.9625 6.67574 11.8125C6.22574 11.7 5.77574 11.9625 5.62574 12.4125L5.06324 14.4375L4.80074 13.275C4.72574 12.9 4.38824 12.6 3.97574 12.6C3.56324 12.6 3.22574 12.9 3.15074 13.275L2.92574 14.4375L2.36324 12.375C2.25074 11.925 1.76324 11.6625 1.31324 11.8125C0.900738 11.925 0.638238 12.375 0.750738 12.825L2.28824 18.1125C2.40074 18.4875 2.73824 18.7125 3.11324 18.7125H3.15074C3.52574 18.7125 3.86324 18.45 3.93824 18.075Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
