"use client";

import { Key } from "@react-types/shared";
import { VariantProps, cva } from "class-variance-authority";
import { ArrowDownIcon, ArrowUpIcon } from "icons";
import React, { useState } from "react";
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select as AriaSelect,
  SelectValue,
} from "react-aria-components";

import { Text } from "../Text/Text";

export interface IOptions {
  id: Key;
  title: string;
}
export interface ISelectProps extends VariantProps<typeof SelectButtonClasses> {
  label?: string;
  placeholder?: string;
  options: IOptions[];
  errorMessage?: string;
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  value?: IOptions["id"] | null | undefined;
  className?: string;
  onSelection?: (key: IOptions["id"]) => void;
}

export function Select({
  label,
  placeholder,
  intent,
  options,
  isDisabled = false,
  isRequired = false,
  errorMessage = "",
  helperMessage = "",
  className,
  value,
  size,
  onSelection,
}: Readonly<ISelectProps>): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<
    IOptions["id"] | null | undefined
  >(value);

  return (
    <AriaSelect
      className="flex flex-col gap-1"
      placeholder={placeholder}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDisabled={isDisabled}
      selectedKey={selectValue}
      onSelectionChange={(selected) => {
        setSelectValue(selected);
        if (selected) {
          onSelection?.(selected);
        }
      }}
    >
      <Label>
        <Text variant="small">
          {label}
          {isRequired && (
            <Text variant="small" intent="secondary" className="ml-1">
              (required)
            </Text>
          )}
        </Text>
        <Button
          className={SelectButtonClasses({
            intent,
            isOpen,
            size,
            className,
          })}
        >
          <SelectValue />
          {isOpen ? (
            <ArrowUpIcon className="w-3 h-3" />
          ) : (
            <ArrowDownIcon className="w-3 h-3" />
          )}
        </Button>
      </Label>
      {!isOpen && errorMessage ? (
        <Text variant="small" intent="danger">
          {errorMessage}
        </Text>
      ) : (
        <Text variant="small" intent={intent}>
          {helperMessage}
        </Text>
      )}
      <Popover className={selectPopoverClasses()} offset={0}>
        {isOpen && <div className="h-0.5 bg-black mx-2 rounded" />}

        <ListBox className={SelectListClasses()}>
          {options?.map((option) => (
            <ListBoxItem
              id={option.id}
              key={option.id}
              className={SelectListItemClasses()}
            >
              {option.title}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </AriaSelect>
  );
}

const SelectButtonClasses = cva(
  "flex items-center justify-between border rounded-md hover:border-primary relative p-2 outline-none text-sm font-medium h-10",
  {
    variants: {
      intent: {
        primary: "border-background-dark",
        success: "border-success",
        warning: "border-warning",
        danger: "border-danger",
      },
      isOpen: {
        true: "border-b-0 rounded-b-none",
      },
      size: {
        xs: "w-20",
        sm: "w-[155px]",
        md: "w-60",
        lg: "w-72",
        xl: "w-80",
        full: "w-full",
      },
    },
    defaultVariants: {
      intent: "primary",
      size: "md",
    },
  }
);
const selectPopoverClasses = cva(
  "w-[--trigger-width] border bg-white z-50 shadow border-background-dark rounded  border-t-0 rounded-t-none entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out"
);
const SelectListClasses = cva(
  "flex flex-col overflow-auto max-h-40 flex-1 w-full outline-none"
);
const SelectListItemClasses = cva(
  "outline-none hover:bg-primaryLight p-2 cursor-pointer text-sm selected:bg-primaryLight",
  {
    variants: {
      selected: {
        true: "bg-primary",
      },
    },
    defaultVariants: {
      selected: false,
    },
  }
);
