import { cva } from "class-variance-authority";
import React, { ReactNode } from "react";
import { TabList as AriaTabList } from "react-aria-components";

import { Tab } from "./Tab";

export interface ITabListProps {
  children: ReactNode;
  className?: string;
}

export function TabList({ children, className }: ITabListProps): JSX.Element {
  return (
    <AriaTabList
      aria-label="Tabs for myGwork on boarding"
      className={tabListClasses({ className })}
    >
      {children}
    </AriaTabList>
  );
}
TabList.Tab = Tab;
const tabListClasses = cva("flex flex-row gap-10 justify-center");
