/* eslint-disable react/no-array-index-key */
import { MyGWorkSmallIcon } from "icons";
import React from "react";

export interface IRatingProps {
  rating: number;
}

export function Rating({ rating = 1 }: IRatingProps): JSX.Element {
  return (
    <div className="flex gap-4">
      {[...Array(Math.round(rating))].map((_value, index) => (
        <MyGWorkSmallIcon className="w-5 h-5" key={index} />
      ))}
    </div>
  );
}
