"use client";

import React, { ReactNode } from "react";
import { DialogTrigger } from "react-aria-components";

import { PopoverContent } from "./PopoverContent";
import { PopoverTrigger } from "./PopoverTrigger";

export function Popover({
  children,
  isOpen,
  onOpenChange,
}: {
  children: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}): JSX.Element {
  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
      {children}
    </DialogTrigger>
  );
}

Popover.Content = PopoverContent;
Popover.Trigger = PopoverTrigger;
