import * as React from "react";
import { SVGProps } from "react";
export function BoxIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={14}
        height={14}
        rx={4.5}
        stroke="currentColor"
      />
    </svg>
  );
}
