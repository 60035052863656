import * as React from "react";
import { SVGProps } from "react";
export function InstagramNoColorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={41} height={41} rx={2} fill="silver" fillOpacity={0.2} />
      <path
        d="M22.383 20.963c0-1.161-.98-2.106-2.185-2.106-1.204 0-2.184.945-2.184 2.106 0 1.162.98 2.106 2.184 2.106 1.205 0 2.185-.944 2.185-2.106ZM25.63 10H14.54C11.486 10 9 12.396 9 15.341v1.488h8.153c.86-.59 1.91-.94 3.045-.94s2.185.35 3.046.94h7.925v-1.488c0-2.945-2.485-5.341-5.54-5.341Zm2.244 5.08a.607.607 0 0 1-.616.593h-1.902a.607.607 0 0 1-.616-.593v-1.834c0-.326.277-.593.616-.593h1.902c.339 0 .616.267.616.593v1.834Zm-2.412 5.883c0 2.798-2.361 5.073-5.263 5.073-2.902 0-5.263-2.275-5.263-5.073 0-.77.18-1.5.5-2.155H9v7.31c0 2.946 2.486 5.342 5.54 5.342H25.63c3.055 0 5.54-2.396 5.54-5.342v-7.31h-6.208c.32.654.5 1.385.5 2.155Z"
        fill="#7B83AC"
        fillOpacity={0.3}
      />
    </svg>
  );
}
