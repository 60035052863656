"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { VariantProps, cva } from "class-variance-authority";
import { ArrowDownIcon } from "icons";
import React, { useCallback } from "react";
import { Label } from "react-aria-components";
import Select, { ActionMeta, MultiValue } from "react-select";
import { Text } from "ui-components";

export interface IMultiSelectOptions {
  value: string;
  label: string;
}
export interface IMultiSelectProps
  extends VariantProps<typeof SelectDropdownWrapper> {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  options?: IMultiSelectOptions[];
  selectValuesIds?: string;
  onChangeSelection?: ({
    newSelectValues,
    newSelectValuesIds,
  }: {
    newSelectValues: IMultiSelectOptions[];
    newSelectValuesIds: string;
  }) => void;
}

export const MultiSelect = ({
  label,
  selectValuesIds,
  placeholder = "",
  errorMessage = "",
  helperMessage = "",
  isRequired = false,
  isDisabled = false,
  options,
  size,
  onChangeSelection,
}: IMultiSelectProps): JSX.Element => {
  const dropDownIcon = useCallback(
    () => (
      <div className="p-2 cursor-pointer">
        <ArrowDownIcon className="w-3 h-3" />
      </div>
    ),
    []
  );

  const value = options
    ?.filter((option) => {
      const finding = selectValuesIds
        ?.split(",")
        ?.find((singleValue) => singleValue === option.value);
      return finding;
    })
    ?.map((option) => option);

  const handleChangeValue = (
    newValue: MultiValue<{
      value: string;
      label: string;
    }>,
    _actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ): void => {
    const newSelectValueIds = newValue.map((item) => item.value);
    const newSelectValue = newValue.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    onChangeSelection?.({
      newSelectValuesIds: newSelectValueIds.toString(),
      newSelectValues: newSelectValue,
    });
  };

  return (
    <div className={SelectDropdownWrapper({ size })}>
      <Label>
        <Text variant="small">
          {label}
          {isRequired && (
            <Text variant="small" intent="secondary" className="ml-1">
              (required)
            </Text>
          )}
        </Text>

        <Select
          isMulti
          placeholder={placeholder}
          isDisabled={isDisabled}
          value={value}
          onChange={handleChangeValue}
          options={options}
          minMenuHeight={10}
          menuPlacement="auto"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => dropDownIcon(),
          }}
          styles={customStyles}
        />
        {errorMessage ? (
          <Text variant="small" intent="danger">
            {errorMessage}
          </Text>
        ) : (
          <Text variant="small">{helperMessage}</Text>
        )}
      </Label>
    </div>
  );
};

const SelectDropdownWrapper = cva("flex flex-col gap-1", {
  variants: {
    size: {
      xs: "w-20",
      sm: "w-[155px]",
      md: "w-60",
      lg: "w-72",
      xl: "w-80",
      full: "w-full flex-1",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? "1px solid #EB773D" : "1px solid #C0C0C0",
    borderRadius: "4px",
    boxShadow: null,
    "&:hover": {
      border: "1px solid #EB773D",
    },
    minHeight: "40px",
    paddingBottom: state.isFocused ? "5px" : "0px",
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? "1px solid #EB773D" : "1px solid #C0C0C0",
    borderTopColor: "#000000",
    borderTopWidth: "2px",
    borderRadius: "8px",
    margin: "-6px",
    shadow: "none",
    borderBottomColor: "#C0C0C0",
    borderBottomWidth: "1px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    boxShadow: "0px",
    marginLeft: "0.2px",
    padding: "5px 0px",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#EB773D4D" : "",
    color: "#000000",
    fontSize: "14px",
    padding: "4px",
    fontWeight: "semibold",
    "&:hover": {
      backgroundColor: "#EB773D4D",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    padding: "4px",
    color: "#535455",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#ffffFF",
    borderRadius: "4px",
    marginRight: "2px",
    border: "1px solid #C0C0C0",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#000000",
    fontSize: "10px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#00000080",
    "&:hover": {
      backgroundColor: "#ffffFF",
      color: "#EB773D",
    },
  }),
};
