import * as React from "react";
import { SVGProps } from "react";
export function AppleLightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.387 0h.106c.086 1.058-.318 1.848-.808 2.42-.482.57-1.141 1.12-2.208 1.037-.07-1.043.334-1.775.824-2.346C8.755.58 9.588.105 10.387 0ZM13.615 11.01v.03a8.726 8.726 0 0 1-1.25 2.408c-.475.655-1.059 1.537-2.101 1.537-.9 0-1.498-.58-2.421-.595-.976-.016-1.513.484-2.406.61h-.304c-.655-.095-1.184-.614-1.57-1.082a9.476 9.476 0 0 1-2.177-5.45v-.672c.069-1.635.863-2.964 1.92-3.608.556-.343 1.322-.634 2.175-.504.366.057.74.182 1.067.306.31.119.698.33 1.066.319.248-.007.496-.137.747-.229.735-.265 1.456-.57 2.406-.426 1.141.172 1.951.68 2.452 1.462-.966.614-1.73 1.54-1.599 3.122.116 1.437.951 2.277 1.995 2.772Z"
        fill="#fff"
      />
    </svg>
  );
}
