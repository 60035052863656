import { cva, type VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";

export interface ITextProps extends VariantProps<typeof textClass> {
  children: ReactNode;
  className?: string | ReactNode;
  component?: "span" | "p" | "div";
}

export function Text({
  intent,
  variant,
  className,
  align,
  children,
  component: Component = "span",
}: ITextProps): JSX.Element {
  return React.createElement(
    Component, // Use the Component variable as the type
    {
      className: textClass({
        intent,
        variant,
        align,
        className,
      }),
    },
    children
  );
}

const textClass = cva("", {
  variants: {
    intent: {
      "brand-primary": "text-primary",
      primary: "text-brand-body-primary",
      secondary: "text-brand-body-secondary",
      blue: "text-brand-blue",
      danger: "text-brand-danger",
      success: "text-brand-success",
      warning: "text-brand-warning",
      white: "text-white",
    },
    variant: {
      small: "text-xs font-normal",
      base: "text-sm font-medium",
      "base-bold": "text-sm font-bold",
      "base-thin": "text-sm font-thin",
      large: "text-base lg:text-lg font-semibold",
    },
    align: {
      center: "text-center",
      left: "text-left",
      right: "text-right",
    },
  },
  defaultVariants: {
    intent: "primary",
    variant: "base",
    align: "left",
  },
});
