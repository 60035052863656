import * as React from "react";
import { SVGProps } from "react";
export function DollorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.75H1.5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1H10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 12.25h9a1 1 0 0 0 1-1v-5M2.525 5.857a.25.25 0 0 1 0-.5M2.525 5.857a.25.25 0 0 0 0-.5M8.975 5.857a.25.25 0 0 1 0-.5M8.975 5.857a.25.25 0 0 0 0-.5M6.814 4.496a.833.833 0 0 0-.786-.556h-.645a.744.744 0 0 0-.16 1.47l.983.215a.834.834 0 0 1-.178 1.648h-.556a.834.834 0 0 1-.786-.555M5.75 3.94v-.833M5.75 8.107v-.834"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
