import { ReactNode } from "react";
import { Button } from "react-aria-components";

export const PopoverTrigger = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => (
  <Button aria-label="Notifications" className="ring-0 outline-0">
    {children}
  </Button>
);
