import * as React from "react";
import { SVGProps } from "react";
export function ArrowRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m.5 17 9-8-9-8" stroke="#000" strokeWidth={1.5} />
    </svg>
  );
}
