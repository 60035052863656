import * as React from "react";
import { SVGProps } from "react";

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 1.5C6.35218 1.5 5.97064 1.65804 5.68934 1.93934C5.40804 2.22064 5.25 2.60218 5.25 3V4.5H6.75V3H15V11.25H13.5V12.75H15C15.3978 12.75 15.7794 12.592 16.0607 12.3107C16.342 12.0294 16.5 11.6478 16.5 11.25V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H6.75ZM3 5.25C2.60218 5.25 2.22064 5.40804 1.93934 5.68934C1.65804 5.97064 1.5 6.35218 1.5 6.75V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H11.25C11.6478 16.5 12.0294 16.342 12.3107 16.0607C12.592 15.7794 12.75 15.3978 12.75 15V6.75C12.75 6.35218 12.592 5.97064 12.3107 5.68934C12.0294 5.40804 11.6478 5.25 11.25 5.25H3ZM3 6.75H11.25V15H3V6.75Z"
                fill="#ADAFBB"
            />
        </svg>
    );
}