import * as React from "react";
import { SVGProps } from "react";
export function ChevronRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 6 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.293 10.207a1 1 0 0 1 0-1.414L3.586 5.5.293 2.207A1 1 0 0 1 1.707.793l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0Z"
      />
    </svg>
  );
}
