import * as React from "react";
import { SVGProps } from "react";
export function DeleteCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#delete-circle_svg__a)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m9.5 4.5-5 5M4.5 4.5l5 5M7 13.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z" />
      </g>
      <defs>
        <clipPath id="delete-circle_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
