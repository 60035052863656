import * as React from "react";
import { SVGProps } from "react";

export function JPEGIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={19}
            height={24}
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Group">
                <path
                    id="Vector"
                    d="M17.7738 6.22505L12.5988 1.12505C12.2988 0.825049 11.8863 0.675049 11.4738 0.675049H2.47383C1.19883 0.675049 0.111328 1.72505 0.111328 3.03755V11.85C0.111328 12.3 0.486328 12.7125 0.973828 12.7125C1.46133 12.7125 1.83633 12.3375 1.83633 11.85V3.00005C1.83633 2.62505 2.13633 2.32505 2.51133 2.32505H10.9863V7.12505C10.9863 7.57505 11.3613 7.98755 11.8488 7.98755H16.6113V11.625C16.6113 12.075 16.9863 12.4875 17.4738 12.4875C17.9613 12.4875 18.3363 12.1125 18.3363 11.625V7.35005C18.2238 6.93755 18.0738 6.52505 17.7738 6.22505ZM12.6363 3.52505L15.4488 6.30005H12.6363V3.52505Z"
                    fill="black"
                />
                <path
                    id="Vector_2"
                    d="M17.3992 19.425C16.9492 19.425 16.5367 19.8 16.5367 20.2875V21.0375C16.5367 21.4125 16.2367 21.7125 15.8617 21.7125H2.47422C2.09922 21.7125 1.79922 21.4125 1.79922 21.0375V20.5125C1.79922 20.0625 1.42422 19.65 0.936719 19.65C0.449219 19.65 0.0742188 20.025 0.0742188 20.5125V21C0.0742188 22.275 1.12422 23.3625 2.43672 23.3625H15.8242C17.0992 23.3625 18.1867 22.3125 18.1867 21V20.25C18.2242 19.8 17.8492 19.425 17.3992 19.425Z"
                    fill="black"
                />
                <path
                    id="Vector_3"
                    d="M7.91211 18.2625V16.0125H9.74961C10.1996 16.0125 10.6121 15.6375 10.6121 15.15V12.675C10.6121 12.225 10.2371 11.8125 9.74961 11.8125H7.08711C6.63711 11.8125 6.22461 12.1875 6.22461 12.675V18.2625C6.22461 18.7125 6.59961 19.125 7.08711 19.125C7.57461 19.125 7.91211 18.7125 7.91211 18.2625ZM7.91211 13.5375H8.88711V14.325H7.91211V13.5375Z"
                    fill="black"
                />
                <path
                    id="Vector_4"
                    d="M1.72422 19.0875H4.38672C4.83672 19.0875 5.24922 18.7125 5.24922 18.225V12.675C5.24922 12.225 4.87422 11.8125 4.38672 11.8125C3.89922 11.8125 3.52422 12.1875 3.52422 12.675V17.4H1.68672C1.23672 17.4 0.824219 17.775 0.824219 18.2625C0.824219 18.75 1.27422 19.0875 1.72422 19.0875Z"
                    fill="black"
                />
                <path
                    id="Vector_5"
                    d="M15.5234 13.5375C15.9734 13.5375 16.3859 13.1625 16.3859 12.675C16.3859 12.1875 16.0109 11.8125 15.5234 11.8125H 12.4484C11.9984 11.8125 11.5859 12.1875 11.5859 12.675V18.2625C11.5859 18.7125 11.9609 19.125 12.4484 19.125H15.5984C16.0484 19.125 16.4609 18.75 16.4609 18.2625V15.4875C16.4609 15.0375 16.0859 14.625 15.5984 14.625H14.3984C13.9484 14.625 13.5359 15 13.5359 15.4875C13.5359 15.975 13.9109 16.35 14.3984 16.35H14.7734V17.4375H13.3109V13.5375H15.5234Z"
                    fill="black"
                />
            </g>
        </svg>
    );
}