"use client";

/* eslint-disable react/prop-types */
import { cva, VariantProps } from "class-variance-authority";
import { CloseIcon } from "icons";
import { ReactNode } from "react";
import {
  Dialog,
  Modal as AriaModal,
  ModalOverlay,
  ModalOverlayProps,
  DialogTrigger,
  Button,
} from "react-aria-components";

import { Text } from "../Text/Text";

export interface IModalProps
  extends ModalOverlayProps,
    VariantProps<typeof ariaModalClass> {
  title?: string;
  triggerNode?: ReactNode;
  isBackgroundBlur?: boolean;
  children: ReactNode;
  closeIcons?: boolean;
  onOpenRequest?: () => void;
  animatedModalClassName?: string;
  paddingY?: string;
}

export function Modal({
  title = "",
  triggerNode,
  closeIcons = true,
  isBackgroundBlur = false,
  children,
  background,
  onOpenRequest,
  animatedModalClassName = "md:min-w-[760px] h-[540px] flex flex-1 items-center justify-center",
  paddingY = "py-10",
  ...props
}: Readonly<IModalProps>): JSX.Element {
  return (
    <DialogTrigger>
      {triggerNode ? (
        <Button onPress={onOpenRequest} className="outline-none w-full">
          {triggerNode}
        </Button>
      ) : null}
      <ModalOverlay
        className={({ isEntering, isExiting }) => ` 
          fixed inset-0 z-50 overflow-y-auto bg-black/10 flex min-h-full items-center justify-center p-4 text-center 
          ${isEntering ? "animate-in fade-in duration-300 ease-out" : ""}
          ${isExiting ? "animate-out fade-out duration-200 ease-in" : ""}
          ${isBackgroundBlur ? "backdrop-blur" : ""}
        `}
        {...props}
      >
        <AriaModal
          // className={({ isEntering, isExiting }) => `
          //   w-full relative max-w-md overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl ${animatedModalClassName}
          //   ${isEntering ? "animate-in zoom-in-95 ease-out duration-300" : ""}
          //   ${isExiting ? "animate-out zoom-out-95 ease-in duration-200" : ""}
          // `}
          className={({ isEntering, isExiting }) =>
            ariaModalClass({
              isEnteringClass: isEntering,
              isExitingClass: isExiting,
              className: animatedModalClassName,
              background,
            })
          }
        >
          <Dialog
            role="dialog"
            className={`flex flex-1 flex-grow w-full h-full justify-center items-center flex-col outline-none ${paddingY}`}
          >
            {({ close }) => (
              <>
                <Text variant="large" className="absolute top-2 left-4">
                  {title}
                </Text>
                {closeIcons && (
                  <Button
                    onPress={close}
                    className="absolute top-4 right-4 outline-none z-50"
                  >
                    <CloseIcon className="w-4 h-4" />
                  </Button>
                )}
                {children}
              </>
            )}
          </Dialog>
        </AriaModal>
      </ModalOverlay>
    </DialogTrigger>
  );
}

const ariaModalClass = cva(
  " w-full relative max-w-md overflow-hidden rounded-2xl   text-left align-middle ",
  {
    variants: {
      background: {
        white: "bg-white shadow-xl",
        transparent: "bg-transparent",
        black: "bg-black",
      },
      isEnteringClass: {
        true: "animate-in zoom-in-95 ease-out duration-900",
        false: "",
      },
      isExitingClass: {
        true: "animate-out zoom-out-95 ease-in duration-200",
        false: "",
      },
    },
    defaultVariants: {
      background: "white",
    },
  }
);
