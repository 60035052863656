import { VariantProps, cva } from "class-variance-authority";
import { SpinnerIcon } from "icons";

export type ISpinnerProps = VariantProps<typeof spinnerClass>;

export function Spinner({ intent, size }: ISpinnerProps): JSX.Element {
  return <SpinnerIcon className={spinnerClass({ intent, size })} />;
}
const spinnerClass = cva("animate-spin", {
  variants: {
    intent: {
      primary: ["fill-black"],
      secondary: ["fill-white"],
      "brand-primary": ["fill-brand-primary"],
      success: "fill-success",
      danger: "fill-danger",
    },
    size: {
      xs: ["h-2 w-2"],
      sm: ["h-3 w-3"],
      md: ["h-5 w-5"],
      lg: ["h-8 w-8"],
      xl: ["h-10 w-10"],
    },
  },
  defaultVariants: {
    intent: "primary",
    size: "md",
  },
});
