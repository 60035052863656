import { NodataFoundIcon } from "icons";

import { Text } from "../Text/Text";

interface IPopoverProps {
  className?: string;
  text?: string;
}

export function Empty({
  className,
  text = "No data",
}: IPopoverProps): JSX.Element {
  return (
    <div className={`text-gray-500 w-full mt-[5%] ${className}`}>
      <div className="w-full h-full flex items-center justify-center">
        <NodataFoundIcon />
      </div>
      <div className="w-full text-center">
        <Text className="text-center">{text}</Text>
      </div>
    </div>
  );
}
