import * as React from "react";
import { SVGProps } from "react";
export function DownloadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 11v1a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1M5 7.5 7.5 10 10 7.5M7.5 10V1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
