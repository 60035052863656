import * as React from "react";
import { SVGProps } from "react";
export function EarthIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#earth_svg__a)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7 13.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z" />
        <path d="M1 9.5h1.75A1.75 1.75 0 0 0 4.5 7.75v-1.5A1.75 1.75 0 0 1 6.25 4.5 1.75 1.75 0 0 0 8 2.75V.57M13.5 6.9a3.56 3.56 0 0 0-1.62-.4H9.75a1.75 1.75 0 1 0 0 3.5A1.25 1.25 0 0 1 11 11.25v.87" />
      </g>
      <defs>
        <clipPath id="earth_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
