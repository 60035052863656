"use client";

import { VariantProps, cva } from "class-variance-authority";
import { ArrowLeftIcon, ArrowRightIcon } from "icons";
import { ReactNode, useEffect, useMemo, useState } from "react";

export interface IPaginationProps extends VariantProps<typeof pageLinkClass> {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  isShowResultEnable?: boolean;
  onPageChange: (page: number) => void;
}
// will show all pages when total pages is less then 8
const showsAllPagesLimit = 8;
// start splitting pages and add dots [...] between
const startSeparatingPageFrom = 5;

const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export function Pagination({
  currentPage,
  totalPages,
  totalRecords,
  isShowResultEnable = false,
  onPageChange,
  size,
}: IPaginationProps): JSX.Element {
  const [currentPagesIndex, setCurrentPagesIndex] =
    useState<number>(currentPage);

  useEffect(() => {
    if (currentPage) {
      setCurrentPagesIndex(currentPage);
    }
  }, [currentPage]);

  const pageRange: { left?: number[]; center?: number[]; right?: number[] } =
    useMemo(() => {
      if (totalPages <= showsAllPagesLimit) {
        return {
          left: range(1, totalPages),
        };
      }
      if (currentPagesIndex < startSeparatingPageFrom) {
        return {
          left: range(1, startSeparatingPageFrom),
          right: range(totalPages - 1, totalPages),
        };
      }
      if (currentPagesIndex > totalPages - startSeparatingPageFrom) {
        return {
          left: range(1, 1),
          right: range(totalPages - startSeparatingPageFrom, totalPages),
        };
      }
      if (
        currentPagesIndex >= startSeparatingPageFrom &&
        currentPagesIndex < totalPages - 2
      ) {
        return {
          left: range(1, 1),
          center: range(currentPagesIndex - 1, currentPagesIndex + 1),
          right: range(totalPages, totalPages),
        };
      }

      return {};
    }, [currentPagesIndex, totalPages]);

  function renderPageNumber(items?: number[], showDots?: boolean): ReactNode {
    if (items) {
      return (
        <>
          {showDots && (
            <span className="bg-background-900 border-background-700 relative inline-flex items-center border px-4 py-2 text-sm font-medium text-gray-700">
              ...
            </span>
          )}
          {items.map((pageIndex) => (
            <button
              key={pageIndex}
              type="button"
              onClick={() => {
                setCurrentPagesIndex(pageIndex);
                onPageChange(pageIndex);
              }}
              className={pageLinkClass({
                isSelectedPage: pageIndex === currentPagesIndex,
                size,
              })}
            >
              {pageIndex}
            </button>
          ))}
        </>
      );
    }
    return null;
  }

  function handleNextPageClick(): void {
    if (currentPagesIndex < totalPages) {
      setCurrentPagesIndex(currentPagesIndex + 1);
      onPageChange(currentPagesIndex + 1);
    }
  }

  function handlePreviousPageClick(): void {
    if (currentPagesIndex > 1) {
      setCurrentPagesIndex(currentPagesIndex - 1);
      onPageChange(currentPagesIndex - 1);
    }
  }

  return (
    <div className={paginationClass({ justifyEnd: !isShowResultEnable })}>
      {isShowResultEnable && (
        <div data-testid="results-test-id">
          <p className="text-body-primary text-sm">
            Showing <span className="font-medium">{currentPage}</span> to{" "}
            <span className="font-medium">{totalPages}</span> of{" "}
            <span className="font-medium">{totalRecords}</span> results
          </p>
        </div>
      )}
      <div
        className="isolate inline-flex -space-x-px rounded-full shadow-sm"
        aria-label="Pagination"
      >
        <button
          type="button"
          data-testid="chevron-previous-test-id"
          className={pageLinkClass({ isRightChevron: false, size })}
          onClick={handlePreviousPageClick}
        >
          <span className="sr-only">Previous</span>
          <ArrowLeftIcon width={6} height={11} color="#6B7280" />
        </button>
        {renderPageNumber(pageRange.left)}
        {renderPageNumber(pageRange.center, true)}
        {renderPageNumber(pageRange.right, true)}

        <button
          type="button"
          data-testid="chevron-next-test-id"
          className={pageLinkClass({ isRightChevron: true, size })}
          onClick={handleNextPageClick}
        >
          <span className="sr-only">Next</span>
          <ArrowRightIcon width={6} height={11} color="#6B7280" />
        </button>
      </div>
    </div>
  );
}

const paginationClass = cva(["flex items-center flex-wrap gap-4"], {
  variants: {
    justifyEnd: {
      true: "justify-center",
      false: "justify-center",
    },
  },
  compoundVariants: [{}],
  defaultVariants: {},
});

const pageLinkClass = cva(
  [
    "relative inline-flex items-center border border-background-700 bg-background-900 text-sm font-medium text-body-primary hover:bg-background-700",
  ],
  {
    variants: {
      isSelectedPage: {
        true: " border-primary-400 bg-primary text-primary-500 border-r-1 z-20",
        false: "",
      },
      isRightChevron: {
        true: "rounded-r-full border",
        false: "rounded-l-full border",
      },
      size: {
        xs: "px-2 py-1 text-xs",
        sm: "px-2.5 py-1 text-xs",
        md: "px-3 md:px-4 py-2",
        lg: "px-5 py-2.5",
        xl: "px-6 py-3",
      },
    },
    compoundVariants: [{}],
    defaultVariants: {
      size: "md",
    },
  }
);
