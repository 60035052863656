import * as React from "react";
import { SVGProps } from "react";
export function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m20.29 19-4.62-4.343m0 0a7.987 7.987 0 0 0 1.845-2.595c.428-.971.648-2.011.648-3.062 0-1.05-.22-2.091-.648-3.062a7.987 7.987 0 0 0-1.844-2.595 8.564 8.564 0 0 0-2.76-1.734 8.98 8.98 0 0 0-3.257-.61 8.981 8.981 0 0 0-3.256.61 8.564 8.564 0 0 0-2.761 1.734C2.041 4.843 1.145 6.878 1.145 9s.896 4.157 2.492 5.657S7.397 17 9.654 17c2.257 0 4.42-.843 6.017-2.343Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
