"use client";

import { VariantProps, cva } from "class-variance-authority";
import { ArrowDownIcon, CloseIcon } from "icons";
import { Ref, useContext, useRef } from "react";
import {
  Button,
  ComboBox,
  Group,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  ComboBoxStateContext,
  ComboBoxProps,
} from "react-aria-components";

import { replaceSpecialCharacters } from "../../utils/string-functions";
import { Text } from "../Text/Text";

export interface ISearchDropdownOptions {
  id: string | number;
  name: string;
}
export interface ISearchDropdownProps<T extends object>
  extends ComboBoxProps<T>,
    VariantProps<typeof SelectButtonClasses> {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  helperMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isLocation?: boolean;
  hasValue?: boolean;
  options?: ISearchDropdownOptions[];
  onClearButtonPress?: () => void;
  inputReference?: Ref<HTMLInputElement> | undefined;
}

function ComboBoxClearButton({
  onClearPress,
}: Readonly<{
  onClearPress?: () => void;
}>): JSX.Element {
  const state = useContext(ComboBoxStateContext);
  return (
    <Button
      slot={null}
      className={`clear-button outline-none ${
        state?.inputValue !== "" ? "visible" : "hidden"
      }`}
      aria-label="Clear"
      onPress={() => {
        state?.setSelectedKey("");
        onClearPress?.();
      }}
    >
      <CloseIcon className="w-2.5 h-2.5" />
    </Button>
  );
}

export function SearchDropdown<T extends object>({
  label,
  placeholder,
  errorMessage = "",
  helperMessage = "",
  options,
  intent,
  isDisabled = false,
  isRequired = false,
  isLocation = false,
  hasValue = false,
  className,
  size,
  onClearButtonPress,
  inputReference,
  ...props
}: Readonly<ISearchDropdownProps<T>>): JSX.Element {
  let calculatedIntent: "primary" | "success" | "warning" | "danger";

  // Automatically focus the input if there's an error

  if (intent) {
    calculatedIntent = intent;
  } else if (errorMessage) {
    calculatedIntent = "danger";
  } else if (hasValue) {
    calculatedIntent = "success";
  } else {
    calculatedIntent = "primary";
  }

  const inputRef = useRef<HTMLInputElement>();

  return (
    <ComboBox
      {...props}
      className={SelectDropdownWrapper({ size, className })}
      onOpenChange={(state) => {
        if (!isLocation && !state && inputRef) {
          inputRef.current?.blur();
        }
      }}
    >
      <Label>
        <Text variant="small">
          {label}
          {isRequired && (
            <Text variant="small" intent="secondary" className="ml-1">
              (required)
            </Text>
          )}
        </Text>

        <Group
          isDisabled={isDisabled}
          className={SelectButtonClasses({ intent: calculatedIntent, size })}
        >
          <Input
            ref={inputReference}
            placeholder={placeholder}
            disabled={isDisabled}
            autoComplete="off"
            className="outline-none  data-[disabled]:bg-gray-200 appearance-none pointer-events-none w-full text-sm placeholder-black truncate mr-4"
          />
          <div className="flex items-center justify-center gap-x-2">
            {!isDisabled && (
              <>
                <ComboBoxClearButton
                  onClearPress={() => {
                    onClearButtonPress?.();
                  }}
                />

                <Button
                  className={options && options.length > 0 ? "" : "hidden"}
                  isDisabled={isDisabled}
                >
                  <ArrowDownIcon className="w-3 h-3" />
                </Button>
              </>
            )}
          </div>
        </Group>
      </Label>
      {errorMessage ? (
        <Text variant="small" intent="danger">
          {errorMessage}
        </Text>
      ) : (
        <Text variant="small" intent={intent}>
          {helperMessage}
        </Text>
      )}

      <Popover
        className={selectPopoverClasses({ size })}
        offset={4}
        crossOffset={isLocation ? 23 : 14}
        placement="bottom"
      >
        <div className="h-0.5 bg-black mx-2 rounded" />

        <ListBox className={SelectListClasses()} selectionMode="single">
          {options?.map((item) => (
            <ListBoxItem
              id={item.id.toString()}
              key={item.id.toString()}
              className={SelectListItemClasses()}
            >
              {replaceSpecialCharacters(item.name)}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}

const SelectDropdownWrapper = cva(" appearance-none flex flex-col gap-1", {
  variants: {
    size: {
      xs: "w-20",
      sm: "w-[155px]",
      md: "w-60",
      lg: "w-72",
      xl: "w-80",
      full: "w-full flex-1",
    },
  },
  defaultVariants: {
    size: "md",
  },
});
const SelectButtonClasses = cva(
  "flex items-center data-[disabled]:bg-gray-200 justify-between border p-2 border-1 rounded-md h-10 hover:border-primary focus-within:border-primary",
  {
    variants: {
      intent: {
        primary: "border-background-dark",
        success: "border-success",
        warning: "border-warning",
        danger: "border-danger",
      },
      isOpen: {
        true: "border-b-0 rounded-b-none",
      },
      size: {
        xs: "w-20",
        sm: "w-[155px]",
        md: "w-60",
        lg: "w-72",
        xl: "w-80",
        full: "w-full",
      },
    },
    defaultVariants: {
      intent: "primary",
      size: "md",
    },
  }
);
const selectPopoverClasses = cva(
  "border bg-white z-50 shadow border-background-dark rounded border-t-0 rounded-t-none entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out",
  {
    variants: {
      size: {
        xs: "w-20",
        sm: "w-[155px]",
        md: "w-60",
        lg: "w-72",
        xl: "w-80",
        full: "w-full max-w-[--trigger-width]",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);
const SelectListClasses = cva(
  "flex flex-col overflow-auto overflow-x-hidden max-h-[300px] flex-1 w-full outline-none "
);
const SelectListItemClasses = cva(
  "outline-none hover:bg-primaryLight p-2 cursor-pointer text-sm selected:bg-primaryLight focus:bg-primaryLight",
  {
    variants: {
      selected: {
        true: "bg-primary",
      },
    },
    defaultVariants: {
      selected: false,
    },
  }
);
