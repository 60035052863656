import { usePathname } from "next/navigation";

import { locales, defaultLocale } from "../../i18nConfig";

export function useCurrentLocale(): string {
  const pathname = usePathname();
  // eslint-disable-next-line prefer-destructuring
  const currentLocale = pathname?.split("/")[1] ?? "";

  const isCurrentLocale = locales.includes(currentLocale);

  return isCurrentLocale ? currentLocale : defaultLocale;
}
