import * as React from "react";
import { SVGProps } from "react";
export function TickIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.25 1.083-7 7L.042 4.875l.822-.822 2.386 2.38L9.427.26l.823.822Z"
        fill="#EB773D"
      />
    </svg>
  );
}
