import * as React from "react";
import { SVGProps } from "react";
export function HandsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 56 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#hands_svg__a)" fill="#000">
        <path d="m19.428 2.524-.698.343a3.637 3.637 0 0 1-2.788.16l-1.166-.411a.867.867 0 0 0-.205-.104L7.393.055a1.038 1.038 0 0 0-1.314.64L.057 18.238c-.092.263-.07.549.045.788.115.24.332.435.595.526l7.165 2.457c.114.035.229.057.331.057.435 0 .835-.274.983-.697l5.68-16.525.389.137a5.767 5.767 0 0 0 4.4-.252l.697-.342a3.629 3.629 0 0 1 2.765-.172l13.178 4.537c-.138.549-.412 1.2-.915 1.555-.56.388-1.394.388-2.491.011l-5.874-2.011a1.034 1.034 0 0 0-.824.068 1.087 1.087 0 0 0-.513.652c-.012.034-.824 3.074-3.418 4.468-1.634.88-3.668.914-6.057.103a1.037 1.037 0 0 0-1.314.64 1.038 1.038 0 0 0 .64 1.314c1.371.469 2.663.709 3.863.709 1.394 0 2.674-.309 3.84-.938 2.32-1.245 3.52-3.428 4.068-4.73l4.903 1.68c1.748.605 3.212.514 4.343-.275 1.817-1.257 1.908-3.806 1.92-3.92a1.037 1.037 0 0 0-.697-1.006L23.77 2.25a5.783 5.783 0 0 0-4.342.275ZM7.565 19.712 2.354 17.93 7.69 2.352l5.211 1.783-5.337 15.577Z" />
        <path d="m46.879 20.89-2.458 2.262a31.39 31.39 0 0 1-7.462 5.097l-13.04 6.377a1.157 1.157 0 0 1-1.554-.537 1.157 1.157 0 0 1 .537-1.555l.16-.08 7.131-3.485a1.035 1.035 0 1 0-.903-1.863l-7.131 3.486-3.588 1.76a1.158 1.158 0 0 1-1.555-.537 1.152 1.152 0 0 1 .526-1.543l2.709-1.326 7.748-3.794a1.034 1.034 0 1 0-.903-1.863l-7.76 3.806-.297.148-3.703 1.817a1.152 1.152 0 0 1-1.543-.526 1.157 1.157 0 0 1 .538-1.554l1.28-.628 2.617-1.28.731-.354 6.011-2.949a1.034 1.034 0 1 0-.902-1.863l-6.514 3.189-2.846 1.394a1.167 1.167 0 0 1-1.555-.537 1.121 1.121 0 0 1-.056-.88c.102-.297.308-.526.582-.663l4.972-2.434c.514-.252.731-.869.48-1.383a1.034 1.034 0 0 0-1.383-.48l-4.972 2.434a3.18 3.18 0 0 0-1.634 1.851 3.247 3.247 0 0 0 .149 2.47c.24.479.582.88.994 1.177a3.241 3.241 0 0 0 .418 4.398 3.23 3.23 0 0 0 2.119.802 3.122 3.122 0 0 0 .332 1.496 3.235 3.235 0 0 0 2.902 1.806c.48 0 .96-.103 1.418-.331l.73-.355c.035.389.138.766.31 1.132a3.235 3.235 0 0 0 2.902 1.805c.48 0 .96-.102 1.418-.331l13.039-6.377a33.434 33.434 0 0 0 7.954-5.429l2.915-2.685 6.674-3.269c.514-.251.731-.868.48-1.383L47.735.695a1.036 1.036 0 0 0-1.383-.48l-6.812 3.326a1.035 1.035 0 0 0-.48 1.382L46.88 20.89ZM46.33 2.534l7.234 14.8-4.948 2.423-7.234-14.8 4.948-2.423Z" />
      </g>
      <defs>
        <clipPath id="hands_svg__a">
          <path fill="#fff" d="M0 0h56v36.797H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
