import * as React from "react";
import { SVGProps } from "react";
export function FileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#file_svg__a)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m.913 12.6-.41-7a.5.5 0 0 1 .5-.56h4.609a.51.51 0 0 1 .49.38l.4 1.62h6.497a.5.5 0 0 1 .5.54l-.39 5a1 1 0 0 1-1 .92H1.913a1 1 0 0 1-1-.9ZM3.501 3V1a.5.5 0 0 1 .5-.5h8.5a.5.5 0 0 1 .5.5v4" />
      </g>
      <defs>
        <clipPath id="file_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
