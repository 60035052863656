/* eslint-disable quotes */
export const replaceSpecialCharacters = (text: string): string => {
  if (!text) {
    return "";
  } // Handle null or undefined text

  const specialCharsMap: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
    "&#x2F;": "/",
    "&#x5C;": "\\",
    "&#96;": "`",
  };

  return text.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;|&#x5C;|&#96;/g,
    (match) => specialCharsMap[match] || match // Return the match itself if not found
  );
};
