import * as React from "react";
import { SVGProps } from "react";
export function FacebookNoColorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={41} height={41} rx={2} fill="silver" fillOpacity={0.2} />
      <path
        d="M22.583 21.625h2.605l1.041-4.333h-3.646v-2.167c0-1.116 0-2.167 2.084-2.167h1.562v-3.64c-.34-.046-1.622-.151-2.976-.151-2.828 0-4.836 1.795-4.836 5.091v3.034h-3.125v4.333h3.125v9.208h4.166v-9.208Z"
        fill="#7B83AC"
        fillOpacity={0.3}
      />
    </svg>
  );
}
