import * as React from "react";
import { SVGProps } from "react";
export function MyGWorkSmallIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#my-g-work-small_svg__a)" fill="#FB660A">
        <path d="M8.191 0 4.009 2.023l-1.74 7.853 1.19 1.527 1.857-8.329L11.534 0H8.19Z" />
        <path d="M12.916 0 5.89 3.542l-.406 1.913 7.458-3.747 6.23 3.049-2.101-2.678L12.916 0Z" />
        <path d="m11.257 3.204 7.472 3.717 1.527 6.926.744-3.356-.985-4.649-7.048-3.493-1.71.855Z" />
        <path d="m19.918 15.188-2.91 3.695-3.01 1.498 4.299-5.554-1.846-8.331 1.716.842 1.751 7.85Z" />
        <path d="m12.11 19.474-6.896.046 3.023 1.47 4.62.01 4.83-6.336-.444-1.905-5.132 6.715Z" />
        <path d="m3.97 18.895-2.85-3.744-.748-3.355 4.301 5.552 8.298-.002-1.178 1.537-7.822.012Z" />
        <path d="M1.054 5.807 0 10.44l4.901 6.277 1.902-.014-5.191-6.666 1.516-6.93-2.074 2.7Z" />
      </g>
      <defs>
        <clipPath id="my-g-work-small_svg__a">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
