import * as React from "react";
import { SVGProps } from "react";
export function UserSingleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 11.518A5.259 5.259 0 1 0 11 1a5.259 5.259 0 0 0 0 10.518ZM21 22.036a10.504 10.504 0 0 0-10-7.295 10.502 10.502 0 0 0-10 7.295h20Z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
