import * as React from "react";
import { SVGProps } from "react";
export function CopyrightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#copyright_svg__a)"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 7a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Z" />
        <path d="M8.957 5a1.5 1.5 0 0 0-1.415-1h-1a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 1.5 1.5h1a1.5 1.5 0 0 0 1.415-1" />
      </g>
      <defs>
        <clipPath id="copyright_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
