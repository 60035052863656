import * as React from "react";
import { SVGProps } from "react";
export function VerifyEamilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={180}
      height={150}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M168.912 6.71h-63.13a17.72 17.72 0 0 0-15.88 9.866l-58.983 119.41c-3.188 6.451 1.503 14.007 8.699 14.007H97.38a21.8 21.8 0 0 0 19.54-12.14l59.114-119.672c2.607-5.282-1.234-11.471-7.123-11.471Z"
        fill="#03020C"
      />
      <path
        d="M168.171 18.181c2.607-5.282-1.234-11.471-7.123-11.471h-63.13a17.715 17.715 0 0 0-15.88 9.873l-5.896 11.944h86.917l5.105-10.338.007-.008Z"
        fill="#03020C"
      />
      <path
        d="M76.15 28.52 30.46 121.012h86.918l45.688-92.493H76.149Z"
        fill="#0E93DE"
      />
      <path
        d="M23.062 135.993C19.874 142.444 24.565 150 31.76 150h57.763a21.802 21.802 0 0 0 19.541-12.14l8.321-16.848H30.468l-7.399 14.981h-.007Z"
        fill="#03020C"
      />
      <path
        d="M76.098 134.053c-2.309 4.177-7.944 7.556-12.59 7.556-4.648 0-6.55-3.386-4.241-7.556s7.943-7.556 12.59-7.556c4.648 0 6.55 3.386 4.241 7.556ZM133.303 18.828l-17.907-.058c-.827 0-1.241-.625-.922-1.395.32-.77 1.249-1.395 2.084-1.395h17.994c.864.007 1.285.646.936 1.431-.348.785-1.328 1.417-2.185 1.417ZM112.405 17.367c-.421 1.025-1.656 1.853-2.752 1.846-1.097-.007-1.649-.836-1.235-1.853.414-1.017 1.641-1.86 2.745-1.86s1.656.836 1.234 1.867h.008Z"
        stroke="#fff"
        strokeWidth={0.97}
        strokeMiterlimit={10}
      />
      <path
        d="m102.115 54.507.393 42.072H83.607S35.107 109.141 9.86 97.96L7.995 38.837h37.388c-2.701-7.665-3.594-16.5-2.556-24.542 1.147-8.9 5.816-12.612 9.992-14.123 1.8-.654 3.717.647 3.79 2.565.341 8.9 2.062 32.257 10.848 36.15 10.9 4.832 34.651 15.628 34.651 15.628l.007-.007Z"
        fill="#C34A27"
      />
      <path
        d="M8.321 60.922c2.82 0 5.105-3.441 5.105-7.686 0-4.246-2.285-7.687-5.105-7.687-2.819 0-5.104 3.441-5.104 7.687 0 4.245 2.285 7.686 5.104 7.686ZM8.321 77.487c2.82 0 5.105-3.442 5.105-7.687s-2.285-7.686-5.105-7.686c-2.819 0-5.104 3.441-5.104 7.686s2.285 7.687 5.104 7.687ZM8.321 94.603c2.82 0 5.105-3.441 5.105-7.686s-2.285-7.687-5.105-7.687c-2.819 0-5.104 3.442-5.104 7.687s2.285 7.686 5.104 7.686Z"
        fill="#C34A27"
      />
      <path
        d="M32.872 37.63H6.949A6.95 6.95 0 0 0 0 44.583v.073a6.95 6.95 0 0 0 6.949 6.952h25.923a6.951 6.951 0 0 0 6.949-6.952v-.073a6.951 6.951 0 0 0-6.95-6.953Z"
        fill="#ED6237"
      />
      <path
        d="M22.895 87.898h2.374c2.462 0 4.56 1.91 4.655 4.373a4.551 4.551 0 0 1-4.539 4.722h-2.49c-1.213 0-2.2-.987-2.2-2.2v-4.687c0-1.213.987-2.201 2.2-2.201v-.007Z"
        stroke="#363680"
        strokeWidth={0.39}
        strokeMiterlimit={10}
      />
      <path
        d="M37.287 53.94H6.949A6.95 6.95 0 0 0 0 60.893v.073a6.95 6.95 0 0 0 6.949 6.952h30.338a6.95 6.95 0 0 0 6.949-6.952v-.073a6.95 6.95 0 0 0-6.95-6.953ZM32.872 70.243H6.949A6.95 6.95 0 0 0 0 77.196v.073a6.95 6.95 0 0 0 6.949 6.952h25.923a6.95 6.95 0 0 0 6.949-6.952v-.073a6.95 6.95 0 0 0-6.95-6.953ZM25.96 86.495H5.764A5.767 5.767 0 0 0 0 92.264v.058a5.767 5.767 0 0 0 5.765 5.769H25.96a5.767 5.767 0 0 0 5.766-5.769v-.058a5.767 5.767 0 0 0-5.766-5.769Z"
        fill="#ED6237"
      />
      <path
        d="M32.727 39.802h-2.375c-1.212 0-2.2.988-2.2 2.202v4.686c0 1.213.988 2.2 2.2 2.2h2.491a4.547 4.547 0 0 0 4.538-4.721c-.094-2.463-2.193-4.374-4.654-4.374v.007ZM35.036 56.367c-1.213 0-2.2.988-2.2 2.201v4.686c0 1.214.987 2.202 2.2 2.202h.973v-9.09h-.973ZM37.417 56.367H36.01v9.089h1.517a4.547 4.547 0 0 0 4.539-4.723c-.095-2.463-2.193-4.373-4.655-4.373l.007.007ZM30.62 72.517c-1.212 0-2.2.989-2.2 2.202v4.686c0 1.213.988 2.201 2.2 2.201h.974v-9.088h-.973ZM33.002 72.517h-1.408v9.089h1.517a4.547 4.547 0 0 0 4.539-4.722c-.095-2.463-2.193-4.374-4.655-4.374l.008.007ZM23.105 88.1c-1.11 0-2.01.902-2.01 2.013v4.28c0 1.111.9 2.012 2.01 2.012h.886v-8.297h-.886v-.007ZM25.276 88.1h-1.285v8.298h1.387a4.145 4.145 0 0 0 4.14-4.309c-.088-2.245-1.998-3.988-4.242-3.988Z"
        fill="#F99C8A"
      />
      <path d="M109.406 49.508H91.608v47.485h17.798V49.51Z" fill="#fff" />
      <path
        d="M152.589 49.508h-43.183v52.041h17.797l25.386-52.04Z"
        fill="#F4B9B9"
      />
      <path
        d="M112.833 34.724c.879 0 1.721.16 2.505.436 1.177-5.442 6.02-9.525 11.814-9.525 1.634 0 3.195.327 4.619.916 1.481-8.907 9.221-15.7 18.545-15.7 10.384 0 18.807 8.42 18.807 18.816 0 2.303-.414 4.512-1.169 6.546a7.49 7.49 0 0 1 4.494-1.496A7.553 7.553 0 0 1 180 42.272c0 3.93-3.006 7.157-6.84 7.52v.036H112.848a7.553 7.553 0 0 1-7.552-7.556 7.552 7.552 0 0 1 7.552-7.555l-.015.007Z"
        fill="#fff"
      />
    </svg>
  );
}
