import * as React from "react";
import { SVGProps } from "react";
export function StarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m7.5 0 2.08 4.945 5.053.583-3.766 3.639 1.041 5.305L7.5 11.776l-4.408 2.696 1.041-5.305-3.766-3.64 5.052-.582L7.5 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
