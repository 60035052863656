import * as React from "react";
import { SVGProps } from "react";

export function FolderOpenIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23.1758 9.075C23.0258 8.85 22.7633 8.7375 22.5008 8.7375H21.0383V7.3875C21.0383 6.525 20.3258 5.85 19.5008 5.85H11.3258L10.0508 3.4875C9.78828 2.9625 9.26328 2.625 8.70078 2.625H2.92578C1.68828 2.625 0.675781 3.6375 0.675781 4.875V19.125C0.675781 20.3625 1.68828 21.375 2.92578 21.375H18.3008C18.9758 21.375 19.5383 20.9625 19.7633 20.325L23.3258 9.8625C23.4008 9.6 23.3633 9.3 23.1758 9.075ZM2.36328 4.875C2.36328 4.575 2.62578 4.3125 2.92578 4.3125H8.62578L10.0883 7.05C10.2383 7.3125 10.5383 7.5 10.8383 7.5H19.3508V8.7H7.46328C7.12578 8.7 6.82578 8.925 6.67578 9.225L2.55078 19.5C2.43828 19.3875 2.36328 19.2375 2.36328 19.0875V4.875ZM18.1883 19.6875H5.36328H4.27578L7.98828 10.425H21.3008L18.1883 19.6875Z"
                fill="black"
            />
            <path
                d="M23.1758 9.075C23.0258 8.85 22.7633 8.7375 22.5008 8.7375H21.0383V7.3875C21.0383 6.525 20.3258 5.85 19.5008 5.85H11.3258L10.0508 3.4875C9.78828 2.9625 9.26328 2.625 8.70078 2.625H2.92578C1.68828 2.625 0.675781 3.6375 0.675781 4.875V19.125C0.675781 20.3625 1.68828 21.375 2.92578 21.375H18.3008C18.9758 21.375 19.5383 20.9625 19.7633 20.325L23.3258 9.8625C23.4008 9.6 23.3633 9.3 23.1758 9.075ZM2.36328 4.875C2.36328 4.575 2.62578 4.3125 2.92578 4.3125H8.62578L10.0883 7.05C10.2383 7.3125 10.5383 7.5 10.8383 7.5H19.3508V8.7H7.46328C7.12578 8.7 6.82578 8.925 6.67578 9.225L2.55078 19.5C2.43828 19.3875 2.36328 19.2375 2.36328 19.0875V4.875ZM18.1883 19.6875H5.36328H4.27578L7.98828 10.425H21.3008L18.1883 19.6875Z"
                fill="black"
            />
        </svg>
    );
}
