import * as React from "react";
import { SVGProps } from "react";
export function YoutubeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.498 6.186a2.843 2.843 0 0 0-2-2.008C19.92 3.68 12 3.68 12 3.68s-7.92 0-9.498.498a2.843 2.843 0 0 0-2 2.008C.021 7.825 0 9.886 0 12s.021 4.175.502 5.814a2.843 2.843 0 0 0 2 2.008C4.08 20.32 12 20.32 12 20.32s7.92 0 9.498-.498a2.843 2.843 0 0 0 2-2.008c.481-1.639.502-3.7.502-5.814s-.021-4.175-.502-5.814Z"
        fill="red"
      />
      <path d="M9.546 15.568V8.432L15.568 12l-6.022 3.568Z" fill="#fff" />
    </svg>
  );
}
