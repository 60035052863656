import * as React from "react";
import { SVGProps } from "react";
export function LinkedinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.962 27.451c6.882 0 12.461-5.579 12.461-12.46 0-6.882-5.579-12.461-12.46-12.461C8.08 2.53 2.5 8.109 2.5 14.99c0 6.882 5.58 12.461 12.461 12.461Z"
        fill="#fff"
      />
      <path
        d="M14.962.038C6.733.038.01 6.77.01 14.99c0 8.219 6.734 14.953 14.953 14.953 8.22 0 14.953-6.734 14.953-14.953 0-8.22-6.724-14.953-14.953-14.953Zm-4.11 22.43H7.74v-9.97h3.112v9.97ZM9.235 11.124c-.997 0-1.75-.874-1.75-1.749 0-.997.753-1.871 1.75-1.871s1.75.752 1.75 1.749c.112.997-.753 1.871-1.75 1.871Zm13.204 11.342h-3.113v-4.862c0-1.12 0-2.614-1.618-2.614-1.617 0-1.871 1.25-1.871 2.614v4.984h-3.113v-9.968h2.99v1.373c.377-.753 1.374-1.618 2.991-1.618 3.113 0 3.743 2.116 3.743 4.74v5.351h-.01Z"
        fill="#0077B5"
      />
    </svg>
  );
}
