"use client";

import { VariantProps, cva } from "class-variance-authority";
import { SearchIcon } from "icons";
import { useState, useEffect, useRef } from "react";
import {
  Input,
  Label,
  SearchField as AriaSearchField,
  Group,
  Button,
} from "react-aria-components";

import { Spinner } from "../spinner/Spinner";
import { Text } from "../Text/Text";

interface ISearchFieldProps extends VariantProps<typeof SearchFieldInputClass> {
  label?: string;
  placeholder?: string;
  debounce?: number;
  isLoading?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  isSearchButtonVisible?: boolean;
}

export function SearchField({
  label,
  placeholder,
  debounce = 500,
  isLoading = false,
  size,
  value = "",
  onChange,
  className,
  isSearchButtonVisible = true,
}: Readonly<ISearchFieldProps>): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm !== value) {
        onChange?.(searchTerm);
      }
    }, debounce);
    return () => clearTimeout(timeoutId);
  }, [debounce, searchTerm, onChange, value]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  return (
    <AriaSearchField className={SearchFieldWrapperClass({})}>
      <Label>{label}</Label>
      <Group
        className={SearchFieldInputClass({
          size,
          className,
          isSearchButtonVisible,
        })}
      >
        <SearchIcon aria-hidden className="w-5 h-5 md:w-8 md:h-8" />
        <Input
          ref={inputRef}
          className="flex flex-1 outline-none h-full items-center placeholder:text-sm rounded-r-full"
          placeholder={placeholder}
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur(); // This will remove focus from the input when Enter is pressed
            }
          }}
        />
        {isLoading && (
          <span className="mr-2.5">
            <Spinner />
          </span>
        )}
        {!!isSearchButtonVisible && (
          <Button
            className="bg-brand-primary h-full rounded-full w-40 hidden md:block"
            onPress={() => inputRef.current && inputRef.current.blur()}
          >
            <Text align="center">Search</Text>
          </Button>
        )}
      </Group>
    </AriaSearchField>
  );
}

const SearchFieldWrapperClass = cva(
  "w-full flex flex-col justify-center items-center"
);
const SearchFieldInputClass = cva(
  "flex items-center item-center pl-4 bg-transparent border shadow gap-2.5 rounded-full outline-none border-body-secondary h-[50px] md:h-[55px]  hover:border-primary focus-within:border-primary",
  {
    variants: {
      size: {
        sm: "w-full lg:w-44",
        md: "w-full lg:w-96",
        lg: "w-full lg:w-[40rem]",
        xl: "w-full lg:w-[50rem]",
        xxl: "w-full lg:md:w-[40rem] lg:w-[60rem] w-96",
        full: "w-full",
      },
      isSearchButtonVisible: {
        true: "pr-0",
        false: "pr-6",
      },
    },
    defaultVariants: {
      size: "md",
      isSearchButtonVisible: true,
    },
  }
);
