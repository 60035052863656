import * as React from "react";
import { SVGProps } from "react";
export function LayersIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.723 10.842a1.815 1.815 0 0 1-1.446 0L1.508 6.781a.861.861 0 0 1 0-1.538l8.77-4.093a1.815 1.815 0 0 1 1.445 0l8.77 4.062a.862.862 0 0 1 0 1.538l-8.77 4.092ZM21 11.535l-9.385 4.323a1.539 1.539 0 0 1-1.277 0L1 11.535M21 16.535l-9.385 4.323a1.539 1.539 0 0 1-1.277 0L1 16.535"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
