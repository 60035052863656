import * as React from "react";
import { SVGProps } from "react";
export function LinkedinLightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19.5} cy={19.5} r={19.5} fill="#fff" />
      <g clipPath="url(#linkedin-light_svg__a)" fill="#0077B5">
        <path d="M13.889 16.112H9.672c-.187 0-.339.138-.339.307v12.253c0 .17.152.307.339.307h4.216c.187 0 .338-.137.338-.306V16.419c0-.17-.151-.307-.338-.307ZM11.782 10.02C10.248 10.02 9 11.15 9 12.536c0 1.387 1.248 2.515 2.782 2.515 1.533 0 2.78-1.128 2.78-2.515 0-1.386-1.247-2.514-2.78-2.514ZM24.611 15.808c-1.693 0-2.945.658-3.704 1.407v-.796c0-.17-.151-.307-.339-.307h-4.037c-.187 0-.339.138-.339.307v12.253c0 .17.152.307.34.307h4.206c.187 0 .338-.137.338-.306V22.61c0-2.043.614-2.84 2.188-2.84 1.714 0 1.85 1.277 1.85 2.945v5.958c0 .169.152.306.339.306h4.208c.187 0 .339-.137.339-.306V21.95c0-3.038-.64-6.143-5.389-6.143Z" />
      </g>
      <defs>
        <clipPath id="linkedin-light_svg__a">
          <path fill="#fff" transform="translate(9 10)" d="M0 0h21v19H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
